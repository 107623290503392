import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { FaSpinner, FaExclamationTriangle } from "react-icons/fa";

import ConversationIcon from "./../assets/images/ConversationIcon.svg";
import { useAppDispatch } from "../hooks";
import { setBlocks } from "../state/blocks/data";
import { BlockFetchStatus } from "../types";
import { resetStatus } from "../state/xhr-status/reqStatus";

interface ConversationCardProps {
    title?: string;
    version?: string;
    id: string;
    onOpenStartCallModal: () => void;
    meetingLink: string;
}

function AutoPrompterConversationCard({
    title,
    version,
    id,
    onOpenStartCallModal,
    meetingLink

}: ConversationCardProps) {
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    const session = sessionStorage.getItem("tiller") || localStorage.getItem("tiller");
    const access_token = session ? JSON.parse(session).access_token : null;

    const [loading, setLoading] = useState(false);

    const [blockFetchStatus, setBlockFetchStatus] =
        useState<BlockFetchStatus | null>(null);

    const dispatch = useAppDispatch();

    


    const linkData = {
        meeting_url: meetingLink,
        conversation_id: id, // Ensure this is properly handled, it can be undefined
    };

    async function handleLinkSubmit() {

        try {
            const response = await fetch(`${apiUrl}/start-call/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${access_token}`,
                },
                body: JSON.stringify(linkData),
            });

            const data = await response.json();

            if (response.ok) {
                localStorage.removeItem('tiller-call-id');
                localStorage.setItem('tiller-call-id', data['call_id'])
                console.log("local storage call id:", localStorage.getItem('tiller-call-id'))
                // openPrompterWindow();
            } else {
                
                    console.log({
                        msg: data.message,
                        statusCode: response.status,
                    })
            }
        } catch (error) {
            
                console.log({
                    msg: "Something went wrong. Please try again.",
                    statusCode: 500,
                })
            
        }
    }

    async function fetchAndStoreBlocks() {

        try {
            const session = sessionStorage.getItem("tiller") || localStorage.getItem("tiller");
            const access_token = session
                ? JSON.parse(session).access_token
                : null;

            const response = await fetch(
                `${apiUrl}/conversation/${id}`,
                {
                    method: "GET", // or 'POST' depending on your needs
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${access_token}`, // Include the token in the Authorization header
                    },
                }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();

            dispatch(setBlocks(data.blocks));

            if (data.blocks.length > 0) {
                setBlockFetchStatus(BlockFetchStatus.OK);
                localStorage.setItem(
                    "tiller-popup-blocks",
                    JSON.stringify(data.blocks)
                );
                localStorage.setItem(
                    "tiller-popup-conv",
                    JSON.stringify(title)
                );
                dispatch(resetStatus()); // reset notifs
            } else {
                console.error("`Blocks are empty")
                setBlockFetchStatus(BlockFetchStatus.Empty);
                // setEmptyOrFailedNotif(
                //     `Blocks for "${title}" are empty`,
                //     BlockFetchStatus.Empty
                // );
            }
        } catch (error) {
            console.error("Failed to fetch conversation:", error);
            setBlockFetchStatus(BlockFetchStatus.Failed);
            // setEmptyOrFailedNotif(
            //     `Loading blocks for "${title}" failed. Please try again.`,
            //     BlockFetchStatus.Failed
            // );
        }
    }

    async function handleClickStartCall() {
        setLoading(true);
        await fetchAndStoreBlocks();
        await handleLinkSubmit()
        setLoading(false);

        onOpenStartCallModal();
    }





    return (
        <div className={`bg-white shadow-[0_2px_18px_-6px_rgba(0,0,0,0.2)] mb-4 px-6 py-3 w-[100%]  rounded-xl ${loading ? "cursor-not-allowed" : "cursor-pointer"}`}
            onClick={() => handleClickStartCall()}>
            <div className="flex items-center justify-between ">
                <div className="flex flex-row items-center ">
                    <img src={ConversationIcon} alt="block icon" />
                    <div className="ml-2">
                        <p className=" text-base font-medium ">{title}</p>
                        <p className="text-xs text-custom-gray lg:text-sm">
                            version {version}
                        </p>
                    </div>
                    
                </div>
                {loading && (
                        <FaSpinner className="ml-2 inline animate-spin" />
                    )}
                    {!loading &&
                        blockFetchStatus !== BlockFetchStatus.OK &&
                        blockFetchStatus !== null && (
                            <div
                                title={`loading blocks ${blockFetchStatus}`}
                            >
                                <FaExclamationTriangle color="yellow" />
                            </div>
                        )}
            </div>
        </div>
    );
}

export default AutoPrompterConversationCard;
