import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { useAppDispatch } from "../hooks";
import { setError, setFailed } from "../state/xhr-status/reqStatus";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
    id: string | number;
    prompt: string;
}

enum Vote {
    Up = "Up",
    Down = "Down",
    NotUsed = "Not Used"
}

function Prompt({ id, prompt }: Props) {
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

    const [voteCast, setVoteCast] = useState<Vote | null>(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    async function handleClickVote(vote: Vote) {
        const currentVote = vote === voteCast ? Vote.NotUsed : vote;
        const previousVote = voteCast; // Save the previous vote state
        setVoteCast(currentVote === Vote.NotUsed ? null : currentVote); // Optimistically update UI

        const session = sessionStorage.getItem("tiller") || localStorage.getItem("tiller");
        if (!session) {
            navigate("/signin");
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/vote/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${JSON.parse(session).access_token}`,
                },
                body: JSON.stringify({
                    prompt_id: id,
                    call_id: localStorage.getItem("tiller-call-id"),
                    vote: currentVote,
                }),
            });

            const data = await response.json(); // Parse JSON response

            if (!response.ok) {
                // If server response is not OK, revert to the previous vote state
                setVoteCast(previousVote);
                dispatch(setFailed({
                    msg: `${data.message}. Try again. Prompt "${prompt}"`,
                    statusCode: response.status,
                }));
            }
        } catch (error) {
            // If an error occurs, revert to the previous vote state
            setVoteCast(previousVote);
            dispatch(setError({
                msg: `Something went wrong. Vote not cast for prompt: "${prompt}". Please try again.`,
                statusCode: 500,
            }));
        }
    }

    useEffect(() => {
        const handleBeforeUnload = () => {
            localStorage.removeItem("tiller-call-id");
        };
        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    return (
        <>
            <div className="w-full border border-[grey-98] shadow px-3 py-2 rounded-lg">
                {prompt}
            </div>
            <div className="flex gap-4">
                <button
                    className={voteCast === Vote.Down ? "opacity-100" : "opacity-30"}
                    onClick={() => handleClickVote(Vote.Down)}
                >
                    <FaThumbsDown color="red" />
                </button>
                <button
                    className={voteCast === Vote.Up ? "opacity-100" : "opacity-30"}
                    onClick={() => handleClickVote(Vote.Up)}
                >
                    <FaThumbsUp color="green" />
                </button>
            </div>
        </>
    );
}

export default Prompt;
