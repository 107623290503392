import React, { useState, useEffect } from 'react';
import DashboardLayout from "../../layouts/DashboardLayout";
import AgentCard from "../../components/AgentCard";
import Loader from "../../components/Loaders/Loader";
import SkeletonLoader from '../../components/Loaders/SkeletonLoader';
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import DialogLayout from "../../layouts/DialogLayout";
import InviteModal from '../../components/InviteModal';
import AgentHistoryBlock from '../../components/AgentHistoryBlock';
import { FaRegFrownOpen } from "react-icons/fa";
import { useAuth } from "../../contexts/AuthContext";
import { setError, setFailed, setSuccess } from "../../state/xhr-status/reqStatus";
import { useAppSelector, useAppDispatch } from "../../hooks";
import AppNotifications from "../../components/AppNotifications";

interface Agent {
    id: string;
    name: string;
    created_at: string;
    email: string;
}

function DashboardAgents() {
    const [agents, setAgents] = useState<Agent[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState<{ id: string; name: string } | null>(null);
    const [isOpenAgentBlock, setIsOpenAgentBlock] = useState(false);
    const [activeAgentId, setActiveAgentId] = useState<string | null>(null);
    const [activeAgentName, setActiveAgentName] = useState<string | null>(null);
    const [activeAgentEmail, setActiveAgentEmail] = useState<string | null>(null);
    const { user, isManager } = useAuth();
    const reqStatus = useAppSelector((state) => state.reqStatus.value);
    const dispatch = useAppDispatch();

    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    const session = sessionStorage.getItem("tiller") || localStorage.getItem("tiller");
    const access_token = session ? JSON.parse(session).access_token : null;
    const company_id = user?.company_id;
    const user_id = user?.id;


    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);



    useEffect(() => {
        fetchAgents();
    }, []);


    const fetchAgents = async () => {
        setIsLoading(true); // Re-enable the loading state
        setIsInviteModalOpen(false)
        try {
            const response = await fetch(`${apiUrl}/users/?companyId=${company_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }
            });
            if (!response.ok) {
                dispatch(setFailed({ msg: 'Network response was not ok', statusCode: 500 }))
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setAgents(data);
        } catch (error) {
            dispatch(setError({ msg: `Failed to fetch agents:${error}`, statusCode: 500 }))
            console.error("Failed to fetch agents:", error);
        } finally {
            setIsLoading(false); // Disable loading state
        }
    };


    const deleteAgent = async () => {
        setLoading(true);
        if (!selectedAgent) return;
        try {
            const response = await fetch(`${apiUrl}/delete-user/${selectedAgent.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }
            });

            if (!response.ok) {
                handleCloseDeleteModal();
                fetchAgents();
                dispatch(setFailed({ msg: "Failed to delete the agent", statusCode: 404 }))
                throw new Error('Failed to delete the agent');
            } else {
                handleCloseDeleteModal();
                fetchAgents();
                dispatch(setSuccess({ msg: "Agent removed sucessfully", statusCode: 200 }))
            }


        } catch (error) {
            dispatch(setError({ msg: `Failed to delete agent:${error}`, statusCode: 500 }))
            console.error("Failed to delete agent:", error);
        }
    };

    const handleOpenDeleteModal = (id: string, name: string) => {
        setSelectedAgent({ id, name });
        setIsDeleteModalOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setIsDeleteModalOpen(false);
        setSelectedAgent(null);
    };

    const handleClickOpenInviteModal = () => {
        setIsInviteModalOpen(true);
    }

    const handleCloseInviteModal = () => setIsInviteModalOpen(false);



    return (
        <DashboardLayout>
            <div className="overflow-auto mb-16 lg:pr-60 pr-4">
                <div className="flex flex-row justify-between items-center mb-9">
                    <h2 className="font-bold text-2xl text-gray-700">Agents</h2>
                    {isManager &&
                        <button className="btn-nav btn-aqua px-4 py-2 rounded" onClick={handleClickOpenInviteModal}>
                            Invite
                        </button>}
                </div>
                {reqStatus && <AppNotifications {...reqStatus} />}
                {isLoading ? <SkeletonLoader /> : agents.length > 0 ? (
                    agents.map((agent) => (
                        <AgentCard key={agent.id} name={agent.name} joinDate={agent.created_at} userId={agent.id} currentUserId={user_id} onDelete={() => handleOpenDeleteModal(agent.id, agent.name)} onHistoryClick={() => {
                            setActiveAgentId(agent.id);
                            setActiveAgentName(agent.name);
                            setActiveAgentEmail(agent.email);
                            setIsOpenAgentBlock(true);
                        }} />
                    ))
                ) : (
                    <div className="flex flex-col justify-center items-center mt-24 text-[#838383] ">
                        <div className="mb-2">
                            <FaRegFrownOpen style={{ fontSize: '38px' }} />
                        </div>
                        <div className="text-center">No agents have been added to your workspace.<br />
                            Get in touch with your manager.</div>
                    </div>
                )}
                {isDeleteModalOpen && selectedAgent && (
                    <DeleteConfirmationModal
                        title="Delete Agent?"
                        message={`Are you sure you want to delete ${selectedAgent.name}? This action cannot be undone.`}
                        onDelete={deleteAgent}
                        onCancel={handleCloseDeleteModal}
                        loading={loading}
                    />
                )}

                {isInviteModalOpen && (<DialogLayout>

                    <InviteModal onClose={handleCloseInviteModal} />
                </DialogLayout>)}

                {isOpenAgentBlock && (<AgentHistoryBlock activeAgentId={activeAgentId} activeAgentName={activeAgentName} activeAgentEmail={activeAgentEmail} setIsOpenAgentBlock={setIsOpenAgentBlock} />)}
            </div>
        </DashboardLayout>
    );
}

export default DashboardAgents;
