import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { FaCircle } from "react-icons/fa";
import { EndCallModals } from "../types";

interface Props {
    goTo: (destination: string | null) => void;
    isEndCall: boolean;
    setEndTime?: (date: Date | null) => void; // Assuming this is intended to be optional

}

function PrompterHeader({ isEndCall, goTo, setEndTime }: Props) {
    const [timeElapsed, setTimeElapsed] = useState(0);
    const [endTime, setLocalEndTime] = useState<Date | null>(null); // Local end time state
    const title = JSON.parse(localStorage.getItem("tiller-popup-conv")!!)

    const formattedTime = useMemo(() => {
        const minutes = Math.floor(timeElapsed / 60);
        const seconds = timeElapsed % 60;
        return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    }, [timeElapsed]);

    function handleClickEndCall() {
        goTo(EndCallModals.EndCall);
    }

    useEffect(() => {
        const startTime = Date.now();
        const timer = setInterval(() => {
            if (isEndCall) {
                clearInterval(timer);
                if (!endTime) { // Check local endTime state
                    const newEndTime = new Date();
                    setLocalEndTime(newEndTime); // Set local end time
                    if (setEndTime) {
                        setEndTime(newEndTime); // Set end time using prop function if available
                    }
                }
            } else {
                const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
                setTimeElapsed(elapsedTime);
            }
        }, 1000);

        return () => {
            clearInterval(timer);
            if (!endTime && !isEndCall) { // Only set end time on unmount if it hasn't been set and isEndCall is false
                const finalEndTime = new Date();
                setLocalEndTime(finalEndTime); // Update local state
                if (setEndTime) {
                    setEndTime(finalEndTime); // Set end time using prop function if available
                }
            }
        };
    }, [isEndCall, endTime, setEndTime]); // Include setEndTime in dependencies if using it directly affects the effect

    return (
        <header className="fixed inset-x-0 bg-white z-50 py-4 px-8">
            <div className="flex items-center justify-between h-full">
                <div className="flex justify-between">

                    <img
                        src="logo-black.svg"
                        alt="company logo"
                        width="83px"
                        height="19px"
                    />
                    <div className="flex items-center text-xs font-bold ml-3">
                        {title}
                    </div>
                </div>

                <div className="flex gap-8">
                    <p className="flex gap-2 items-center font-bold font-mono">
                        <FaCircle color="red" />
                        <span className="pt-1">
                            {formattedTime}
                        </span>
                    </p>
                    <button className="btn btn-outline btn-red text-sm hover:bg-red-600" onClick={handleClickEndCall}>
                        End call
                    </button>
                </div>
            </div>
        </header>
    );
}

export default PrompterHeader;
