import { useState } from "react";
import { FaSpinner } from "react-icons/fa";

interface DeleteConfirmationModalProps {
  title: string;
  message: string;
  onDelete: () => void;
  onCancel: () => void;
  loading: boolean
}

function DeleteConfirmationModal({ title, message, onDelete, onCancel, loading }: DeleteConfirmationModalProps) {

  return (
      <div className="fixed inset-0 p-4 flex justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-black before:opacity-50 overflow-auto">
          <div 
            style={{
              width: '447px',
              height: '240px',
              top: '131px',
            }} 
            className="absolute bg-white shadow-lg rounded-2xl p-6">
              <div className="my-2">
                  <h4 className="text-xl font-semibold">{title}</h4>
                  <p className="text-sm text-gray-500 mt-4">{message}</p>
              </div>
              <div className="flex flex-col space-y-2">
                  <button 
                  disabled={loading}
                    type="button" 
                    className="px-6 py-2.5 rounded-md text-white text-sm font-semibold border-none outline-none bg-red-400 hover:bg-red-500 active:bg-red-500"
                    onClick={onDelete}>
                      Delete
                    
                      {loading && (
                                    <FaSpinner className="ml-2 inline animate-spin" />
                                )}
                  </button>
                  <button 
                    type="button" 
                    className="px-6 py-2.5 rounded-md text-black text-sm font-semibold border-2 outline-none  hover:bg-gray-200 active:bg-gray-200"
                    onClick={onCancel}>
                      Cancel
                  </button>
              </div>
          </div>
      </div>
  );
}

export default DeleteConfirmationModal