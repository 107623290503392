import React, { createContext, useContext, useState, ReactNode } from 'react';

interface EditBlockContextType {
    activeBlockId: string | number;
    currentTab: string;
    setCurrentTab: (tab: string) => void;
    handleClickBlock: (blockId: string, tab: string) => void;
    isEditBlockOpen: boolean;
    setIsEditBlockOpen: (choice: boolean) => void;
}

const EditBlockContext = createContext<EditBlockContextType | null>(null);

export function useEditBlockContext() {
    return useContext(EditBlockContext);
}

interface EditBlockProviderProps {
    children: ReactNode;
}

export const EditBlockProvider: React.FC<EditBlockProviderProps> = ({ children }) => {
    const [activeBlockId, setActiveBlockId] = useState<string | number >("");
    const [currentTab, setCurrentTab] = useState<string>('');
    const [isEditBlockOpen, setIsEditBlockOpen] = useState(false);

    const handleClickBlock = (blockId: string | number, tab: string) => {
        setIsEditBlockOpen(true)
        setActiveBlockId(blockId);
        setCurrentTab(tab);
    };

    return (
        <EditBlockContext.Provider value={{ activeBlockId, currentTab, handleClickBlock , setCurrentTab, isEditBlockOpen, setIsEditBlockOpen}}>
            {children}
        </EditBlockContext.Provider>
    );
};