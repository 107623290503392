import { useState } from "react";
import { FaStar, FaRegStar, FaSpinner } from "react-icons/fa";

import PrompterDialogLayout from "../layouts/DialogLayout";

import { useAppSelector, useAppDispatch } from "../hooks";
import { setFailed, setError, setSuccess } from "../state/xhr-status/reqStatus";
import useNotifStateReset from "../hooks/useNotifStateReset";
import AppNotifications from "./AppNotifications";
import { EndCallModals } from "../types";

interface Props {
    goTo: (destination: string|null) => void;
    setRating?: (rating:number)=> void;
}

function RateConversation({ goTo, setRating }:Props) {
    useNotifStateReset(); // reset notifs

    const reqStatus = useAppSelector((state) => state.reqStatus.value);
    const dispatch = useAppDispatch();
    
    const NUM_STARS = Array.from({ length: 5 }, (_, index) => index + 1);
    const [rating, setLocalRating] = useState(0);
    const [loading, setLoading] = useState(false);

    function handleClickSetRating(rating: number) {
        setLocalRating(rating);
    }

    function handleSubmit() {
        if (setRating) {  // Check if setRating is defined
            setRating(rating);
        } else {
            // Optionally handle the case where setRating is not provided
            console.error("Rating function is not provided.");
        }
        
        goTo(EndCallModals.AnyComments);
    }

    return (
        <PrompterDialogLayout>
            <form method="POST" onSubmit={handleSubmit}>
                {reqStatus && <AppNotifications {...reqStatus} />}
                <h3 className="font-bold text-lg mb-2">Rate conversation</h3>
                <p className="text-[--grey-muted]">
                    1 = didn't close, 5 = achieved goal
                </p>
                <ul className="flex gap-2 justify-center text-[--grey-muted] my-4">
                    {NUM_STARS.map((star, idx) => {
                        return (
                            <li
                                key={idx}
                                onClick={() => handleClickSetRating(star)}
                            >
                                {rating >= star && (
                                    <FaStar
                                        size={32}
                                        className="text-green-600"
                                    />
                                )}
                                {!(rating >= star) && <FaRegStar size={32} />}
                            </li>
                        );
                    })}
                </ul>
                <button
                    type="submit"
                    className={`btn btn-aqua w-full mt-4 ${
                        loading ? "cursor-progress bg-[--aqua-dark]" : ""
                    }`}
                    disabled={loading ? true : false}
                >
                    Send
                    {loading && (
                        <FaSpinner className="ml-2 inline animate-spin" />
                    )}
                </button>
            </form>
        </PrompterDialogLayout>
    );
}

export default RateConversation;
