import { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaComment, FaUserFriends, FaCog, FaVideo } from "react-icons/fa";
import logoIcon from './../assets/images/logo-black.svg';
import leaderboardIcon from './../assets/images/leaderboardIcon.svg';
import { Icon } from '@iconify/react';

interface Props {
    onOpenFeedbackModal: () => void;
}

function DashboardSideNav({ onOpenFeedbackModal }: Props) {
    const navigate = useNavigate();
    const location = useLocation(); // Get the current location
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);

    const handleNavSelect = (path: string) => {
        navigate(path);
    };

    // Function to determine if the path matches the current location
    const isActive = (path: string, startsWith: boolean = false) => {
        if (startsWith) {
            return location.pathname.startsWith(path);
        }
        return location.pathname === path;
    };

    const handleLogout = () => {
        localStorage.removeItem('tiller');
        navigate('/signin');
    };

    return (
        <div className="flex flex-col justify-between pt-2 pb-20 absolute inset-y-0 left-0 transform w-60 text-gray-700 -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out h-screen">
            <div>
                <div className="w-[77.96px] h-[18.39px] mt-6 ml-6 border-b border-gray-200 flex items-center justify-center">
                    <Link to="/">
                        <img src={logoIcon} alt="company logo" className="h-10 mr-3" />
                    </Link>
                </div>

                <div className="flex flex-col gap-1 mt-[30px] h-full">
                    <button
                        className={`nav-button ${isActive('/') ? 'active' : ''}`}
                        onClick={() => handleNavSelect('/')}
                    >
                        <Icon icon="mingcute:grid-2-line" className="nav-icon" /> Feed
                    </button>

                    <button
                        className={`nav-button ${isActive('/recordings') || isActive('/recording', true) ? 'active' : ''}`}
                        onClick={() => handleNavSelect('/recordings')}
                    >
                        <Icon icon="fluent-emoji-high-contrast:record-button" className="nav-icon" /> Recordings
                    </button>

                    <button
                        className={`nav-button ${isActive('/playbooks') ? 'active' : ''}`}
                        onClick={() => handleNavSelect('/playbooks')}
                    >
                        <Icon icon="akar-icons:chat-dots" className="nav-icon" /> Playbooks
                    </button>

                    <button
                        className={`nav-button ${isActive('/agents') ? 'active' : ''}`}
                        onClick={() => handleNavSelect('/agents')}
                    >
                        <Icon icon="solar:user-linear" className="nav-icon" /> Agents
                    </button>

                    <button
                        className={`nav-button ${isActive('/settings') ? 'active' : ''}`}
                        onClick={() => handleNavSelect('/settings')}
                    >
                        <Icon icon="hugeicons:settings-01" className="nav-icon" /> Settings
                    </button>
                </div>
            </div>

            <div className='flex flex-col gap-4 ml-6 text-sm'>
                <p className='text-[#585858]'>Tiller Beta 1.2</p>
                <div>
                    <button className='text-[#585858] hover:underline' onClick={onOpenFeedbackModal}>Send feedback</button>
                </div>
                <div>
                    <button className='text-[#ED6459]' onClick={handleLogout}>Log out</button>
                </div>
            </div>
        </div>
    );
}

export default DashboardSideNav;
