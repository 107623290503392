import { useState, useEffect } from "react";
import { FaRegRectangleXmark } from "react-icons/fa6";
import { FaRegSmile, FaRegStar } from "react-icons/fa";
import DashboardLayout from "../../layouts/DashboardLayout";
import ConversationCard from "../../components/ConversationCard";
import StartCallModal from "../../components/StartCallModal";
import ConversationsSkeletonLoader from '../../components/Loaders/ConversationsSkeletonLoader';
import AppNotifications from "../../components/AppNotifications";
import FirstConversationSetup from "../../components/FirstConversationSetup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { ConvModals, BlockFetchStatus, IConvObjective } from "../../types";
import { setError, setFailed, setSuccess } from "../../state/xhr-status/reqStatus";
import { addNewConv, setConvs } from "../../state/convs/data";
import { SESSION_CONVS } from "../../constants";
import DialogLayout from "../../layouts/DialogLayout";
import FirstConversationObjective from "../../components/FirstConversationObjective";
import { useAuth } from "../../contexts/AuthContext";



function DashboardConversations() {
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';


    const conversations = useAppSelector((state) => state.convData.value);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedConvId, setSelectedConvId] = useState<string | undefined>();
    // const [activeTab, setActiveTab] = useState('all');
    const [searchParams, setSearchParams] = useSearchParams();
    const activeTab = searchParams.get('tab') || 'all';
    const reqStatus = useAppSelector((state) => state.reqStatus.value);
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const { user, setUser, isManager } = useAuth();

    const session = localStorage.getItem("tiller");
    const access_token = session ? JSON.parse(session).access_token : null;
    const company_id = user?.company_id;

    function setEmptyOrFailedNotif(msg: string, status: BlockFetchStatus) {
        if (status === BlockFetchStatus.Empty) {
            dispatch(
                setFailed({
                    msg: msg,
                    statusCode: 200,
                })
            );
        } else {
            dispatch(
                setError({
                    msg: msg,
                    statusCode: 500,
                })
            );
        }
    }

    const [isStartCallModalOpen, setIsStartCallModalOpen] = useState(false);

    const handleOpenStartCallModal = (id?: string) => {
        setSelectedConvId(id);
        setIsStartCallModalOpen(true);
    };
    const handleCloseStartCallModal = () => setIsStartCallModalOpen(false);

    const [isPrompterValid, setIsPrompterValid] = useState(false); // only valid when there are blocks to display
    const openPrompterWindow = () => {
        if (!isPrompterValid) return;
        const width = 680; // width of the new window
        const height = window.screen.height; // use the full screen height
        const left = window.screen.width; // position the window on the right side
        const top = 0; // start at the top of the screen

        const prompterWindow = window.open(
            `${window.location.origin}/prompter`, // URL to open
            "_blank", // open in a new window
            `toolbar=no, location=no, directories=no, status=no, menubar=no, 
        scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
        height=${height}, top=${top}, left=${left}`
        );
    };

    const [isActiveModal, setIsActiveModal] = useState<ConvModals | null>(null);
    const handleClickOpenNewConvModal = () => {
        setIsActiveModal(ConvModals.FirstConv);
    };

    const handleNextModal = async (modal: ConvModals | null) => {


        if (!modal) {
            // For the last modal submit, create the conversation
            await createConv();

        }
        setIsActiveModal(modal);
    };

    const [newConvTitle, setConvTitle] = useState("");
    const handleSetConvTitle = (title: string) => {
        setConvTitle(title);
    };
    const [newConvObjective, setConvObjective] =
        useState<IConvObjective | null>(null);
    const handleSetConvObjective = (objective: IConvObjective) => {
        setConvObjective(objective);
    };
    const createConv = async () => {
        try {
            const sessionConvObjective = sessionStorage.getItem(
                "tiller-conv-objective",
            );
            const sessionConvObjectiveCopy = sessionConvObjective ? JSON.parse(sessionConvObjective) : null;
            if (newConvTitle.trim().length === 0 || (!newConvObjective && !sessionConvObjectiveCopy)) {
                throw new Error(
                    "One of objective or conversation title is empty!"
                );
            }
            const payload = {
                title: newConvTitle,
                company_id,
                objective_id: newConvObjective?.id || sessionConvObjectiveCopy.id
            };
            const response = await fetch(
                `${apiUrl}/conversations/`,
                {
                    method: "POST", // or 'POST' depending on your needs
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${access_token}`, // Include the token in the Authorization header
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();

            if (!response.ok) {
                dispatch(
                    setError({
                        msg: data.message,
                        statusCode: 403,
                    })
                );
            }

            if (data.length === 1) dispatch(addNewConv(data[0]));


            navigate(`/conversation-builder/${btoa(data[0].id)}`);
        } catch (error) {
            dispatch(
                setError({
                    msg: `Failed to create conversation: ${error}`,
                    statusCode: 500,
                })
            );
        }
    };

    const handleClickCloseModal = () => {
        setIsActiveModal(null);
    }

    const toggleFavorite = async (convId: string) => {
        const conversationIndex = conversations.findIndex(conv => conv.id === convId);
        if (conversationIndex === -1) return;

        const updatedConversations = [...conversations];
        updatedConversations[conversationIndex] = {
            ...updatedConversations[conversationIndex],
            is_favorite: !updatedConversations[conversationIndex].is_favorite
        };

        dispatch(setConvs(updatedConversations));

        try {
            const response = await fetch(`${apiUrl}/favorites`, {
                method: updatedConversations[conversationIndex].is_favorite ? 'POST' : 'DELETE',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${access_token}`,
                },
                body: JSON.stringify({ conversation_id: convId })
            });

            if (!response.ok) {
                throw new Error('Failed to update favorite status');
            }

        } catch (error) {
            // Revert changes if the server call fails
            updatedConversations[conversationIndex].is_favorite = !updatedConversations[conversationIndex].is_favorite;
            dispatch(setConvs(updatedConversations));
            dispatch(setError({
                msg: `Failed to update favorite status: ${error}`,
                statusCode: 500,
            }))
        }
    };
    const setActiveTab = (tab: string) => {
        setSearchParams({ tab });
    };


    useEffect(() => {
        async function fetchConversations() {
            try {
                const response = await fetch(
                    `${apiUrl}/conversations/?companyId=${company_id}`,
                    {
                        method: "GET", // or 'POST' depending on your needs
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${access_token}`, // Include the token in the Authorization header
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();

                dispatch(setConvs(data));
                sessionStorage.setItem(SESSION_CONVS, JSON.stringify(data)) // Used when the builder page is refreshed by the user
            } catch (error) {
                dispatch(
                    setError({
                        msg: `Failed to fetch conversation: ${error}`,
                        statusCode: 500,
                    })
                );
            }
            setIsLoading(false);
        }

        fetchConversations();
    }, [access_token, company_id, dispatch]); // Empty dependency array means this effect runs once on mount

    return (
        <DashboardLayout>
            <div className="overflow-auto mb-16 lg:pr-60 pr-4">
                <div className="flex flex-row justify-between items-center mb-4">
                    <h2 className="font-bold text-2xl text-gray-700">
                        Playbooks
                    </h2>
                    <button
                        className="btn-nav  btn-aqua px-4 py-2 rounded "
                        onClick={handleClickOpenNewConvModal}
                    >
                        Create
                    </button>
                </div>
                <div className="flex flex-row gap-2 items-center mb-6">
                    <button
                        className={`rounded-[36px] py-2 px-4 border  ${activeTab === 'all' ? 'bg-[#2C81FF] text-white border-[#2C81FF]' : 'bg-[#F2F2F2] text-[#3B3B3B] border-[#0000001A]'}`}
                        onClick={() => setActiveTab('all')}
                    >
                        All
                    </button>
                    <button
                        className={`rounded-[36px] py-2 px-4  border ${activeTab === 'favourites' ? 'bg-[#2C81FF] text-white border-[#2C81FF]' : 'bg-[#F2F2F2] text-[#3B3B3B] border-[#0000001A]'}`}
                        onClick={() => setActiveTab('favourites')}
                    >
                        Favourites
                    </button>
                </div>
                {reqStatus && <AppNotifications {...reqStatus} />}
                {isLoading ? (
                    <ConversationsSkeletonLoader />
                ) : (
                    <>
                        {conversations.length > 0 ? (
                            activeTab === 'all' ? (
                                conversations.map((conversation, index) => (
                                    <ConversationCard
                                        key={conversation.id}
                                        title={conversation.title}
                                        version={conversation.conv_version}
                                        id={conversation.id}
                                        isFavorite={conversation.is_favorite}
                                        toggleFavorite={() => toggleFavorite(conversation.id)}
                                        setEmptyOrFailedNotif={setEmptyOrFailedNotif}
                                        setIsPrompterValid={setIsPrompterValid}
                                        onOpenStartCallModal={handleOpenStartCallModal}
                                    />
                                ))
                            ) : (
                                conversations.filter(conv => conv.is_favorite).length > 0 ? (
                                    conversations
                                        .filter(conv => conv.is_favorite)
                                        .map((conversation, index) => (
                                            <ConversationCard
                                                key={conversation.id}
                                                title={conversation.title}
                                                version={conversation.conv_version}
                                                id={conversation.id}
                                                isFavorite={conversation.is_favorite}
                                                toggleFavorite={() => toggleFavorite(conversation.id)}
                                                setEmptyOrFailedNotif={setEmptyOrFailedNotif}
                                                setIsPrompterValid={setIsPrompterValid}
                                                onOpenStartCallModal={handleOpenStartCallModal}
                                            />
                                        ))
                                ) : (
                                    <div className="flex flex-col justify-center items-center mt-24 text-[#838383]">
                                        <div className="mb-2">
                                            <FaRegStar style={{ fontSize: '38px' }} />
                                        </div>
                                        <div className="text-center">
                                            No favorite playbooks added.
                                        </div>
                                    </div>
                                )
                            )
                        ) : (
                            <div className="flex flex-col justify-center items-center mt-24 text-[#838383]">
                                <div className="mb-2">
                                    <FaRegSmile style={{ fontSize: '38px' }} />
                                </div>
                                <div className="text-center">
                                    Let's get you started - create your first playbook now!
                                    <br />
                                </div>
                            </div>
                        )}
                    </>
                )}

                {isStartCallModalOpen && (
                    <StartCallModal
                        isPrompterValid={isPrompterValid}
                        conversationId={selectedConvId}
                        onClose={handleCloseStartCallModal}
                        openPrompterWindow={openPrompterWindow}
                    />
                )}
                {isActiveModal === ConvModals.FirstConv && (
                    <DialogLayout>
                        <button className="float-right right-0 top-0" onClick={handleClickCloseModal}>
                            <FaRegRectangleXmark
                                className="text-[--aqua-dark] text-3xl"
                                title="close modal"
                            />
                        </button>
                        <FirstConversationSetup
                            onSetConvTitle={handleSetConvTitle}
                            onNextModal={handleNextModal}
                        />
                    </DialogLayout>
                )}
                {isActiveModal === ConvModals.FirstObj && (
                    <DialogLayout>
                        <button className="float-right right-0 top-0" onClick={handleClickCloseModal}>
                            <FaRegRectangleXmark
                                className="text-[--aqua-dark] text-3xl"
                                title="close modal"
                            />
                        </button>
                        <FirstConversationObjective
                            onSetConvObjective={handleSetConvObjective}
                            onNextModal={handleNextModal}
                        />
                    </DialogLayout>
                )}
            </div>
        </DashboardLayout>
    );
}

export default DashboardConversations;
