import DashboardLayout from "../../layouts/DashboardLayout";
import { useState, useEffect } from "react";
import { FaSpinner, FaEyeSlash, FaEye } from "react-icons/fa6";
import SettingsSkeletonLoader from "../../components/Loaders/SettingsSkeletonLoader";
import { useAppSelector, useAppDispatch } from '../../hooks'
import AppNotifications from '../../components/AppNotifications';
import GoogleCalendar from '../../assets/images/GoogleCalendar.png'
import MicrosoftCalendar from '../../assets/images/MicrosoftCalendar.png'
import { setError, setFailed, setSuccess } from "../../state/xhr-status/reqStatus";

interface User {
    email: string;
    name: string;
    password: string;
}

function DashboardSettings() {
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    const session = sessionStorage.getItem("tiller") || localStorage.getItem("tiller");
    const access_token = session ? JSON.parse(session).access_token : null;

    const [loading, setLoading] = useState(false);
    const [msLoading, setMsLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdateName, setIsUpdateName] = useState(false);
    const [isUpdateEmail, setIsUpdateEmail] = useState(false);
    const [isUpdatePassword, setIsUpdatePassword] = useState(false);
    const [oldPwdVisible, setOldPwdVisible] = useState(false);
    const [newPwdVisible, setNewPwdVisible] = useState(false);
    const [isGoogleConnected, setIsGoogleConnected] = useState(false);
    const [isMicrosoftConnected, setIsMicrosoftConnected] = useState(false)
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [lastUpdate, setLastUpdate] = useState('');
    const [oldPwd, setOldPwd] = useState('');
    const [newPwd, setNewPwd] = useState('');

    const reqStatus = useAppSelector((state) => state.reqStatus.value);
    const dispatch = useAppDispatch()

    function handleEmailChange(e: any) {
        setEmail(e.target.value);
    }

    function handleNameChange(e: any) {
        setName(e.target.value);
    }

    function handleOldPwdChange(e: any) {
        setOldPwd(e.target.value);
    }
    function handleNewPwdChange(e: any) {
        setNewPwd(e.target.value);
    }

    const handleUpdate = async (field: string, value: string) => {
        const response = await fetch(`${apiUrl}/user`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`,
            },
            body: JSON.stringify({ field, value }),
        });
        const data = await response.json();
        // setMessage(data.message);
        if (response.ok && field === 'email') {
            setEmail(value);  // Update email in state if email change was successful
            setIsUpdateEmail(false)
        } else if (!response.ok && field === 'email') {
            // setEmailMessage(data.message);

        }
        if (response.ok && field === 'name') {
            setName(value);  // Update name in state if email change was successful
            setIsUpdateName(false)
        }

        if (response.ok && field === 'password') {
            setIsUpdatePassword(false)
            setLastUpdate(data.last_update)
        }
    };

    // function checkPopupBlocker() {
    //     const testPopup = window.open("", "_blank", "width=100,height=100");
    //     if (testPopup) {
    //         testPopup.close();
    //     } else {
    //         alert("Pop-ups are blocked. Please enable pop-ups for this website to use all features.");
    //     }
    // }

    async function handleConnectGoogleCalendar() {
        setLoading(true);
        // await checkPopupBlocker()
        try {

            const response = await fetch(`${apiUrl}/google/authorize/`,
                {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${access_token}`
                    }
                }
            );
            const data = await response.json()
            window.location.href = data.url; // Redirect to the Google login page

        } catch (error) {
            dispatch(
                setError({
                    msg: `Failed to fetch authorization URL: ${error}`,
                    statusCode: 500,
                })
            );
        }

    };

    async function handleDisconnectGoogleCalendar() {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/google/revoke/`,
                {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${access_token}`
                    }
                }
            );
            const data = await response.json()
            setIsGoogleConnected(false)
            if (response.ok) {
                dispatch(setSuccess({ msg: data.message, statusCode: 200 }))
            } else {
                dispatch(
                    setError({
                        msg: data.error,
                        statusCode: 400,
                    })
                );
            }

        } catch (error) {
            dispatch(
                setError({
                    msg: `Error disconnecting Google calendar: ${error}`,
                    statusCode: 500,
                })
            );
            // Handle errors here, e.g., show an error message
        }
        setLoading(false);
    };

    async function handleConnectMicrosoftCalendar() {
        setMsLoading(true);
        try {

            const response = await fetch(`${apiUrl}/microsoft/authorize/`,
                {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${access_token}`
                    }
                }
            );
            const data = await response.json()
            window.location.href = data.url; // Redirect to the Microsoft login page

        } catch (error) {
            dispatch(
                setError({
                    msg: `Failed to fetch authorization URL: ${error}`,
                    statusCode: 500,
                })
            );
        }

    };

    async function handleDisconnectMicrosoftCalendar() {
        setMsLoading(true);
        try {
            const response = await fetch(`${apiUrl}/microsoft/revoke/`,
                {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${access_token}`
                    }
                }
            );
            const data = await response.json()
            setIsMicrosoftConnected(false)
            if (response.ok) {
                dispatch(setSuccess({ msg: data.message, statusCode: 200 }))
            } else {
                dispatch(
                    setError({
                        msg: data.error,
                        statusCode: 400,
                    })
                );
            }

        } catch (error) {
            dispatch(
                setError({
                    msg: `Error revoking Microsoft Credentials: ${error}`,
                    statusCode: 500,
                })
            );

        }
        setMsLoading(false);
    };

    async function fetchUser() {
        setIsLoading(true);
        try {

            const response = await fetch(`${apiUrl}/user`,
                {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${access_token}`
                    }
                }
            );
            const data = await response.json()
            setEmail(data.email)
            setName(data.name)
            setLastUpdate(new Date(data.last_update || data.created_at).toLocaleString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }))
            setIsGoogleConnected(data.has_google_creds)
            setIsMicrosoftConnected(data.has_microsoft_creds)
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            dispatch(
                setError({
                    msg: `Error fetching current user: ${error}`,
                    statusCode: 500,
                })
            );
        }
    }

    useEffect(() => {
        fetchUser();
    }, []);

    return (
        <DashboardLayout>
            <div className="overflow-auto mb-16 lg:pr-60 pr-4">

                <div className="flex flex-row justify-between items-center mb-9">
                    <h2 className="font-bold text-2xl text-gray-700">
                        Settings
                    </h2>

                    <div className="flex flex-row gap-3 items-center mr-2">
                        <p className="font-medium">My Calendars:</p>
                        {isLoading ? <div className="h-4 bg-gray-200 rounded w-[100px] animate-pulse"></div> : (
                            <>
                                {!isGoogleConnected && !isMicrosoftConnected && <p>Not connected</p>}
                                {isGoogleConnected &&
                                    <a href="https://calendar.google.com" target="_blank" rel="noopener noreferrer" title="Open Google Calendar">
                                        <img src={GoogleCalendar} alt="Google Calendar icon" width={"24px"} />
                                    </a>}

                                {isMicrosoftConnected &&
                                    <a href="https://outlook.office365.com/calendar" target="_blank" rel="noopener noreferrer" title="Open Microsoft Calendar">
                                        <img src={MicrosoftCalendar} alt="Microsoft Calendar icon" width={"24px"} />
                                    </a>}
                            </>


                        )}
                    </div>
                </div>

                {reqStatus && (
                    <AppNotifications {...reqStatus} />
                )}

                {isLoading ? <SettingsSkeletonLoader /> : (

                    <div>
                        <div className="flex flex-col gap-3 bg-white px-6 py-4 rounded-2xl shadow-xs mb-4">
                            <div className="flex flex-row justify-between items-center">
                                <p className="font-medium">Personal details</p>
                                <div></div>
                            </div>
                            <div className="flex flex-row justify-between items-center">
                                <p className="text-[#6B7280] text-sm">Name</p>
                                {!isUpdateName && <div className="text-[#6B7280] text-sm">
                                    {name}
                                </div>}
                            </div>
                            {!isUpdateName &&
                                <div className="flex flex-row justify-between items-center">
                                    <button className="custom-button btn-nav flex items-center justify-center py-1 text-xs py-[6px]" onClick={() => setIsUpdateName(true)}>
                                        Update name
                                    </button>
                                    <div></div>
                                </div>}
                            {isUpdateName &&
                                <div className="flex flex-col gap-3">
                                    <input type="text" name="name" id="name" value={name} className="form-input" onChange={handleNameChange} />
                                    <div className="flex flex-row gap-2">
                                        <button className="custom-button btn-nav flex items-center justify-center py-1 text-xs py-[6px]" onClick={() => setIsUpdateName(false)}> Cancel</button>
                                        <button className="btn-aqua btn-nav flex items-center justify-center py-1 text-xs py-[6px]" onClick={() => handleUpdate('name', name)}> Save</button>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="flex flex-col gap-4 bg-white px-6 py-4 rounded-2xl shadow-xs mb-4">
                            <div className="flex flex-row justify-between items-center">
                                <div className="font-medium">Calendar</div>
                                <div></div>
                            </div>
                            <div className="flex flex-row justify-between items-center">
                                <div className="text-[#6B7280] text-sm">Google Calendar</div>
                                {isGoogleConnected ?
                                    <button className="custom-button btn-nav flex items-center justify-center text-xs w-[255px] py-[6px] text-[#ED6459]" onClick={handleDisconnectGoogleCalendar}>
                                        Disconnect your Google Calendar&nbsp;&nbsp;
                                        {loading && (
                                            <FaSpinner className="ml-2 inline animate-spin" />
                                        )}
                                    </button> :

                                    <button className="custom-button btn-nav flex items-center justify-center text-xs w-[255px] py-[6px] " onClick={handleConnectGoogleCalendar}>
                                        Connect your Google Calendar&nbsp;&nbsp;
                                        {loading && (
                                            <FaSpinner className="ml-2 inline animate-spin" />
                                        )}
                                    </button>
                                }
                            </div>

                            <div className="flex flex-row justify-between items-center">
                                <div className="text-[#6B7280] text-sm">Microsoft Calendar</div>
                                {isMicrosoftConnected ?
                                    <button className="custom-button btn-nav flex items-center justify-center text-xs w-[255px] py-[6px] text-[#ED6459]" onClick={handleDisconnectMicrosoftCalendar}>
                                        Disconnect your Microsoft Calendar&nbsp;&nbsp;
                                        {msLoading && (
                                            <FaSpinner className="ml-2 inline animate-spin" />
                                        )}
                                    </button> :

                                    <button className="custom-button btn-nav flex items-center justify-center text-xs w-[255px] py-[6px] " onClick={handleConnectMicrosoftCalendar}>
                                        Connect your Microsoft Calendar&nbsp;&nbsp;
                                        {msLoading && (
                                            <FaSpinner className="ml-2 inline animate-spin" />
                                        )}
                                    </button>
                                }
                            </div>
                        </div>

                        <div className="flex flex-col gap-3 bg-white px-6 py-4 rounded-2xl shadow-xs mb-4">
                            <div className="flex flex-row justify-between items-center">
                                <p className="text-[#6B7280] text-sm">Email</p>
                                {!isUpdateEmail && <div className="text-[#6B7280] text-sm">
                                    {email}
                                </div>}
                            </div>
                            {!isUpdateEmail &&
                                <div className="flex flex-row justify-between items-center">
                                    <button className="custom-button btn-nav flex items-center justify-center py-1 text-xs py-[6px]" onClick={() => setIsUpdateEmail(true)}>
                                        Change email
                                    </button>
                                    <div></div>
                                </div>}
                            {isUpdateEmail &&
                                <div className="flex flex-col gap-3">
                                    <input type="text" name="name" id="name" value={email} className="form-input" onChange={handleEmailChange} />
                                    <div className="flex flex-row gap-2">
                                        <button className="custom-button btn-nav flex items-center justify-center text-xs py-[6px]" onClick={() => setIsUpdateEmail(false)}> Cancel</button>
                                        <button className="btn-aqua btn-nav flex items-center justify-center  text-xs py-[6px]" onClick={() => handleUpdate('email', email)}> Save</button>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="flex flex-col gap-3 bg-white px-6 py-4 rounded-2xl shadow-xs mb-4">
                            <div className="flex flex-row justify-between items-center">
                                <p className="text-[#6B7280] text-sm">Password</p>
                                {!isUpdatePassword && <div className="text-[#6B7280] text-sm">
                                    Last updated {lastUpdate}
                                </div>}
                            </div>
                            {!isUpdatePassword &&
                                <div className="flex flex-row justify-between items-center">
                                    <button className="custom-button btn-nav flex items-center justify-center py-1 text-xs py-[6px]" onClick={() => setIsUpdatePassword(true)}>
                                        Update password
                                    </button>
                                    <div></div>
                                </div>}
                            {isUpdatePassword &&
                                <div className="flex flex-col gap-3">
                                    <div className="relative w-1/3">
                                        <label htmlFor="old-pwd" className="block w-full">Old Password</label>
                                        <input type={oldPwdVisible ? "text" : "password"} value={oldPwd} onChange={handleOldPwdChange} id="old-pwd" className="form-input pr-10" />
                                        <button
                                            type="button"
                                            onClick={() => setOldPwdVisible(!oldPwdVisible)}
                                            className="absolute inset-y-0 right-0 pr-3 mt-6 flex items-center text-sm leading-5"
                                        >
                                            {oldPwdVisible ? <FaEyeSlash /> : <FaEye />}
                                        </button>
                                    </div>

                                    <div className="relative w-1/3">
                                        <label htmlFor="old-pwd" className="block w-full">New Password</label>
                                        <input type={newPwdVisible ? "text" : "password"} value={newPwd} onChange={handleNewPwdChange} id="new-pwd" className="form-input pr-10" />
                                        <button
                                            type="button"
                                            onClick={() => setNewPwdVisible(!newPwdVisible)}
                                            className="absolute inset-y-0 right-0 pr-3 mt-6 flex items-center text-sm leading-5"
                                        >
                                            {newPwdVisible ? <FaEyeSlash /> : <FaEye />}
                                        </button>
                                    </div>

                                    <div className="flex flex-row gap-2">
                                        <button className="custom-button btn-nav flex items-center justify-center text-xs py-[6px]" onClick={() => setIsUpdatePassword(false)}> Cancel</button>
                                        <button className="btn-aqua btn-nav flex items-center justify-center  text-xs py-[6px]" onClick={() => handleUpdate('password', newPwd)}> Save</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>)}
            </div>
        </DashboardLayout>
    )

}

export default DashboardSettings;
