import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IReqStatus {
  value: {
    status: string,
    msg?: string,
    statusCode: number
  } | null;
}

interface IStatusPayload {
    msg?: string,
    statusCode: number
}

// Define the initial state using that type
const initialState: IReqStatus = {
  value: null
}

export const ReqStatus = createSlice({
  name: 'signup-status',
  initialState,
  reducers: {
    setSuccess: (state, action: PayloadAction<IStatusPayload>) => {
      state.value = {
        status: 'success',
        msg: action.payload?.msg,
        statusCode: action.payload.statusCode
      }
    },
    setFailed: (state, action: PayloadAction<IStatusPayload>) => {
      state.value = {
        status: 'failed',
        msg: action.payload?.msg,
        statusCode: action.payload.statusCode
      }
    },
    setError: (state, action: PayloadAction<IStatusPayload>) => {
      state.value = {
        status: 'error',
        msg: action.payload?.msg,
        statusCode: action.payload.statusCode
      }
    },
    resetStatus: (state) => {
      state.value = null;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setSuccess, setFailed, setError, resetStatus } = ReqStatus.actions

export default ReqStatus.reducer