import React from 'react';

const SkeletonLoader: React.FC = () => {
    return (
        <div>
            {Array.from({ length: 10 }).map((_, index) => (
                <div key={index} className="bg-white shadow-[0_2px_18px_-6px_rgba(0,0,0,0.2)] mb-4 px-6 py-3 w-full rounded-xl animate-pulse">
                    <div className="flex items-center justify-between">
                        <div className="flex-grow">
                            <div className="h-4 bg-gray-200 rounded w-1/4 mb-2"></div>
                            <div className="h-3 bg-gray-200 rounded w-1/3"></div>
                        </div>
                        <div className="flex flex-row space-x-2">
                            <div className="w-[89px] h-[38px] bg-gray-200 rounded"></div>
                            <div className="w-[133px] h-[38px] bg-gray-200 rounded"></div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default SkeletonLoader;
