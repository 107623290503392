import { useEffect, useState } from "react";
import AppNotifications from "./AppNotifications";
import { useAppDispatch, useAppSelector } from "../hooks";
import { setError } from "../state/xhr-status/reqStatus";
import useNotifStateReset from "../hooks/useNotifStateReset";
import Loader from "./Loaders/Loader";
import { IConvObjective } from "../types";
import { FaSpinner } from "react-icons/fa6";

interface IProps {
    onSetConvObjective: (objective: IConvObjective) => void;
    onNextModal: (modal: null) => void;
}
function SetupYourFirstConversationObjective({
    onSetConvObjective,
    onNextModal,
}: IProps) {
    useNotifStateReset(); // reset stale notifs
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

    const reqStatus = useAppSelector((state) => state.reqStatus.value);
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [nextLoading, setNextLoading] = useState(false);
    const [convObjective, setConvObjective] = useState<IConvObjective | null>(
        null
    );
    const [objOptions, setObjOptions] = useState<IConvObjective[] | []>([]);

    const handleSetConvObjective = (objective: IConvObjective) => {
        setConvObjective(objective);
    };

    const handleSubmit = async () => {
        setNextLoading(true)
        // e.preventDefault();
        if (!convObjective) {
            dispatch(
                setError({
                    msg: "Please set the conversation objective.",
                    statusCode: 400,
                })
            );
        }
        onSetConvObjective(convObjective!);
        sessionStorage.setItem("tiller-conv-objective", JSON.stringify(convObjective!))
        await onNextModal(null);
        setNextLoading(false)
    };

    useEffect(() => {
        async function fetchObjectives() {
            try {
                const session = sessionStorage.getItem("tiller") || localStorage.getItem("tiller");
                const access_token = session
                    ? JSON.parse(session).access_token
                    : null;
                const response = await fetch(
                    `${apiUrl}/objectives/`,
                    {
                        method: "GET", // or 'POST' depending on your needs
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${access_token}`, // Include the token in the Authorization header
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                console.log("🚀 ~ fetchObjectives ~ data:", data);

                setObjOptions(data);
            } catch (error) {
                console.error("Failed to fetch objectives:", error);
                dispatch(
                    setError({
                        msg: `Failed to fetch objectives: ${error}`,
                        statusCode: 500,
                    })
                );
            }
            setIsLoading(false);
        }

        fetchObjectives();
    }, []); // Empty dependency array means this effect runs once on mount

    return (
        <div>
            <div className="mx-auto">
                {reqStatus && <AppNotifications {...reqStatus} />}
            </div>
            <img
                src="logo-black.svg"
                alt="company logo"
                width="103px"
                className="mx-auto pb-16"
            />
            <h2 className="header-2 text-center">Setup an objective</h2>
            <p className="pb-4 text-[--grey-muted] text-center">
                What's your playbook's objective?
            </p>
            {isLoading ? (
                <div className="h-72 w-full flex items-center justify-center">
                    <Loader />
                </div>
            ) : (
                <>
                    {objOptions.map((objective, index) => {
                        return (
                            <div
                                className={`btn-select ${
                                    convObjective?.id === objective.id
                                        ? "border-2 border-[--aqua]"
                                        : ""
                                }`}
                                key={index}
                                onClick={() =>
                                    handleSetConvObjective(objective)
                                }
                            >
                                <h3>{objective.title}</h3>
                                <p className="text-[--grey-muted]">
                                    {objective.description}
                                </p>
                            </div>
                        );
                    })}
                </>
            )}

            <button
                title={
                    convObjective === null
                        ? "Please select one option"
                        : "Click to sumbit"
                }
                // type="submit"
                className={`btn btn-aqua w-full my-4 ${
                    convObjective === null
                        ? "cursor-not-allowed bg-gray-400 hover:bg-gray-400"
                        : ""
                }`}
                disabled={convObjective === null}
                onClick={handleSubmit}
            >
                Next &nbsp;&nbsp;
                    {nextLoading && (
                            <FaSpinner className="ml-2 inline animate-spin" />
                        )}
            </button>
        </div>
    );
}

export default SetupYourFirstConversationObjective;
