import React, { useEffect, useState } from "react";
import AppNotifications from "./AppNotifications";
import { useAppDispatch, useAppSelector } from "../hooks";
import { setError } from "../state/xhr-status/reqStatus";
import useNotifStateReset from "../hooks/useNotifStateReset";
import Loader from "./Loaders/Loader";
import { FaR } from "react-icons/fa6";
import { FaCopy } from "react-icons/fa";

interface Props {
    onClose: () => void;
}

function InviteModal( {onClose}: Props) {
    useNotifStateReset(); // reset stale notifications
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

    const reqStatus = useAppSelector((state) => state.reqStatus.value);
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [companyCode, setCompanyCode] = useState('');
    const [linkCopied, setLinkCopied] = useState(false);

    useEffect(() => {
        async function fetchCompanyCode() {
            try {
                const session = sessionStorage.getItem("tiller") || localStorage.getItem("tiller");
                const access_token = session ? JSON.parse(session).access_token : null;
                const response = await fetch(
                    `${apiUrl}/company-code/`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${access_token}`,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                setCompanyCode(data.access_code);

            } catch (error) {
                console.error("Failed to fetch company code:", error);
                dispatch(setError({
                    msg: `Failed to fetch company code: ${error}`,
                    statusCode: 500,
                }));
            }
            setIsLoading(false);
        }

        fetchCompanyCode();
    }, []);

    const handleCopyCode = () => {
        navigator.clipboard.writeText(companyCode)
            .then(() => {
                console.log('Company code copied to clipboard!');
            })
            .catch(err => console.error('Failed to copy company code: ', err));
    };

    const handleCopyLink = () => {
        const signupUrl = `${window.location.origin}/user-signup`;
        navigator.clipboard.writeText(signupUrl)
            .then(() => {
                setLinkCopied(true);
                console.log('Signup link copied to clipboard!');
                setTimeout(() => setLinkCopied(false), 2000); // Reset after 2 seconds
            })
            .catch(err => console.error('Failed to copy signup link: ', err));
    };

    return (
        <div>
            <div className="mx-auto">
                {reqStatus && <AppNotifications {...reqStatus} />}
            </div>
            <h2 className="text-xl">1. Share this link:</h2>
            <div className="w-full border border-[grey-98] shadow px-3 py-2 rounded-lg my-4 relative">
                {window.location.origin}/user-signup
                <div className="absolute right-3 group inline-block">

                    
                    <button
                        className="bg-gray-200 hover:bg-gray-300 text-black px-3 py-1 rounded flex items-center justify-center relative"
                        onClick={handleCopyLink}
                        
                    >
                        <FaCopy className="text-[--aqua]" />
                        {linkCopied && (
                            <div className="absolute top-0 right-0 translate-x-full mt-[-10px] w-auto p-2 text-sm text-white bg-black rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10">
                                Copied!
                            </div>
                        )}
                    </button>
                </div>
            </div>



            <h2 className="text-xl my-4">2. Share the organisation code:</h2>
            {isLoading ? (
                <div className="h-32 w-full flex items-center justify-center">
                    <Loader />
                </div>
            ) : (
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={handleCopyCode}
                    className="h-32 w-full flex items-center justify-center bg-[#ECECEC] rounded-lg text-xl"
                >
                    {companyCode || "No Code Available"}
                </div>
            )}
            <button
                className="btn btn-aqua w-full my-4"
                onClick={onClose}
            >
                Close
            </button>
        </div>
    );
}

export default InviteModal;
