import { configureStore } from '@reduxjs/toolkit'
import reqStatusReducer from './xhr-status/reqStatus'
import blocksReducer from './blocks/data';
import convReducer from './convs/data';

const store = configureStore({
  reducer: {
    reqStatus: reqStatusReducer,
    blocksData: blocksReducer,
    convData: convReducer
  },
});

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch