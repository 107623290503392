export interface IPrompt {
    prompt_id: number|string,
    prompt: string,
    position: number
}
export interface IPhase {
    phase_id: number|string,
    title?: string,
    position: number,
    prompts: IPrompt[]
}
export interface IBlock {
    block_id: number|string,
    title: string,
    description: string,
    position: number,
    phases: IPhase[]
}
export interface IConversation {
    id: string;
    title: string;
    conv_version: string;
    is_favorite: boolean;
}
export enum EndCallModals {
    EndCall = "EndCall",
    RateConversation = "RateConversation",
    AnyComments = "AnyComments",
}

export enum BlockFetchStatus {
    OK = "OK",
    Empty = "Empty",
    Failed = "Failed",
}

export enum ConvModals {
    FirstConv = "FirstConvModal",
    FirstObj = "FirstObjModal",
}

export interface IConvObjective {
    id: number;
    title: string;
    description: string;
    created_at?: string;
}