import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../components/Loaders/Loader';

const AutoMeetingRedirector: React.FC = () => {
    const { encodedMeetingLink } = useParams<{ encodedMeetingLink?: string }>();
    const navigate = useNavigate();
    const [retryCount, setRetryCount] = useState(0);
    const [alertShown, setAlertShown] = useState(false);
    const session = localStorage.getItem("tiller");
        
    useEffect(() => {
        
        const decodedMeetingLink = decodeURIComponent(encodedMeetingLink || 'https://meet.google.com/landing');
        if (session) {
            if (decodedMeetingLink && retryCount < 4) {
                const width = 680; 
                const height = window.screen.height; 
                const left = window.screen.width - width;
    
                const tryOpeningWindows = () => {
                    const autoPrompterWindow = window.open(
                        `${window.location.origin}/auto-prompter`,
                        "_blank",
                        `toolbar=no, location=no, directories=no, status=no, menubar=no,
                        scrollbars=no, resizable=no, copyhistory=no, width=${width},
                        height=${height}, top=0, left=${left}`
                    );
    
                    if (!autoPrompterWindow) {
                        if (!alertShown) {
                            alert('Prompter blocked! Please disable your popup blocker and try again.');
                            setAlertShown(true); // alert is only shown once
                        }
                        setTimeout(() => {
                            setRetryCount(retryCount + 1);
                        }, 10000);
                    } else {
                        if (autoPrompterWindow) {
                            setTimeout(() => {
                                autoPrompterWindow.postMessage({ meetingLink: decodedMeetingLink }, '*');
                            }, 500);
                        }
                        window.location.href = decodedMeetingLink;
                    }
                };
    
                tryOpeningWindows();
            } else if (retryCount >= 4) {
                window.location.href = decodedMeetingLink;
            }
        } else {
            window.location.href = decodedMeetingLink;
        }
        

    }, [encodedMeetingLink, retryCount, alertShown]);

    return (
        <div className='h-screen flex justify-center items-center'>
            <p className='z-10'>Redirecting to your meeting...</p>
            <Loader />
        </div>
    );
};

export default AutoMeetingRedirector;