function SettingsSkeletonLoader() {
    return (
        <div>
            <div className="flex flex-col gap-3 bg-white px-6 py-4 rounded-2xl shadow-xs mb-4 animate-pulse">
                <div className="flex flex-row justify-between items-center">
                    <div className="h-5 bg-gray-200 rounded w-[150px]"></div>
                    <div></div>
                </div>
                <div className="flex flex-row justify-between items-center">
                    <div className="h-4 bg-gray-200 rounded w-[70px]"></div>
                    <div className="h-4 bg-gray-200 rounded w-[150px]"></div>
                </div>
                <div className="flex flex-row justify-between items-center">
                    <div className="w-[110px] h-[22.5px] bg-gray-200 rounded"></div>
                    <div></div>
                </div>
            </div>

            <div className="flex flex-col gap-4 bg-white px-6 py-4 rounded-2xl shadow-xs mb-4 animate-pulse">
                <div className="flex flex-row justify-between items-center">
                    <div className="h-5 bg-gray-200 rounded w-[120px]"></div>
                    <div></div>
                </div>
                <div className="flex flex-row justify-between items-center">
                    <div className="h-4 bg-gray-200 rounded w-[160px]"></div>
                    <div className="w-[225px] h-[22.5px] bg-gray-200 rounded"></div>
                </div>
                <div className="flex flex-row justify-between items-center">
                    <div className="h-4 bg-gray-200 rounded w-[160px]"></div>
                    <div className="w-[225px] h-[22.5px] bg-gray-200 rounded"></div>
                </div>
            </div>

            <div className="flex flex-col gap-3 bg-white px-6 py-4 rounded-2xl shadow-xs mb-4 animate-pulse">
                <div className="flex flex-row justify-between items-center">
                    <div className="h-4 bg-gray-200 rounded w-[70px]"></div>
                    <div className="h-4 bg-gray-200 rounded w-[70px]"></div>
                </div>
                <div className="flex flex-row justify-between items-center">
                <div className="w-[110px] h-[22.5px] bg-gray-200 rounded"></div>
                    <div></div>
                </div>
            </div>

            <div className="flex flex-col gap-3 bg-white px-6 py-4 rounded-2xl shadow-xs mb-4 animate-pulse">
                <div className="flex flex-row justify-between items-center">
                    <div className="h-4 bg-gray-200 rounded w-[100px]"></div>
                    <div className="h-4 bg-gray-200 rounded w-[170px]"></div>
                </div>
                <div className="flex flex-row justify-between items-center">
                <div className="w-[110px] h-[22.5px] bg-gray-200 rounded"></div>
                    <div></div>
                </div>
            </div>
        </div>

    )
}

export default SettingsSkeletonLoader