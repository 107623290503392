import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; 

interface User {
  id: string | undefined;
  username: string | null;
  roles: string[];
  company_id: string | undefined;
}

interface AuthContextType {
  user: User | null; // Allow user to be null to handle cases with no token
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  isManager: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(() => {
    const session = localStorage.getItem("tiller");
    if (session) {
      const { access_token } = JSON.parse(session);
      if (access_token) {
        try {
          const decoded = jwtDecode<{ sub: string, user_name: string, roles: string[], company_id: string }>(access_token);
          
          // Validate the required fields, ensuring roles is defined and not empty
          if (decoded.sub && decoded.user_name && decoded.roles && decoded.roles.length > 0 && decoded.company_id) {
            return {
              id: decoded.sub,
              username: decoded.user_name,
              roles: decoded.roles,
              company_id: decoded.company_id
            };
          } else {
            console.error('Token is missing required fields');
            localStorage.removeItem("tiller");
            return null; // Return null if any required field is missing or roles is empty
          }
        } catch (error) {
          console.error('Error decoding the token:', error);
          localStorage.removeItem("tiller");
          return null; // Return null if token decoding fails
        }
      }
    }
    return null; // Return null if no valid token is found
  });

  // useEffect(() => {
  //   if (!user) {
  //     navigate('/signin'); // Navigate to sign-in page if user is null
  //   }
  // }, [user, navigate]);

  const isManager = user && user.roles ? user.roles.includes('manager') : false;

  return (
    <AuthContext.Provider value={{ user, setUser, isManager }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used within an AuthProvider');
  return context;
};

export default AuthProvider;
