import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

import { IBlock, IPhase } from "../../types/index";

interface ICreatePrompt {
  blockId: number | string;
  phaseId: number | string;
}

interface IUpdatePrompt extends ICreatePrompt {
  promptId: number | string;
  prompt: string;
}

interface IDeletePrompt extends ICreatePrompt {
  promptId: number | string;
}

interface IUpdatePhase extends IPhase {
  blockId: number | string;
}

// Define the initial state using that type
const initialState: { value: IBlock[] } = {
  value: [{} as IBlock],
};

interface IReorderPhases {
  blockId: number | string;
  startIndex: number;
  endIndex: number;
}

interface IReorderPrompts {
  blockId: number | string;
  phaseId: number | string;
  startIndex: number;
  endIndex: number;
}

export const BlocksBuilder = createSlice({
  name: "blocks-slice",
  initialState,
  reducers: {
    addBlock: (state, action: PayloadAction<IBlock>) => {
      state.value.push(action.payload);
    },
    patchBlock: (state, action: PayloadAction<Partial<IBlock>>) => {
      state.value.forEach((block, idx) => {
        if (block.block_id.toString() === action.payload.block_id?.toString()) {
          state.value[idx].title = action.payload.title || "";
          state.value[idx].description = action.payload.description || "";
        }
      })
    },
    deleteBlock: (state, action: PayloadAction<number | string>) => {
      const filteredBlock = state.value.filter((block) => {
        return block.block_id.toString() !== action.payload.toString();
      });
      state.value = filteredBlock;
      localStorage.setItem("tiller-block-edit", JSON.stringify(state.value))
    },
    setBlocks: (state, action: PayloadAction<IBlock[]>) => {
      state.value = action.payload;
    },
    addPhase: (state, action: PayloadAction<number | string>) => {
      state.value.forEach((block) => {
        if (block.block_id.toString() === action.payload.toString()) {
          block.phases.push({
            phase_id: uuidv4(),
            title: "",
            position: block.phases.length,
            prompts: [],
          });
        }
      });
    },
    addPrompt: (state, action: PayloadAction<ICreatePrompt>) => {
      state.value.forEach((block) => {
        if (block.block_id.toString() === action.payload.blockId.toString()) {
          block.phases.forEach((phase) => {
            if (phase.phase_id.toString() === action.payload.phaseId.toString()) {
              phase.prompts.push({
                prompt_id: uuidv4(),
                prompt: "",
                position: phase.prompts.length,
              });
            }
          });
        }
      });
    },
    updatePrompt: (state, action: PayloadAction<IUpdatePrompt>) => {
      state.value.forEach((block, blockIdx) => {
        if (block.block_id.toString() === action.payload.blockId.toString()) {
          block.phases.forEach((phase, phaseIdx) => {
            if (phase.phase_id.toString() === action.payload.phaseId.toString()) {
              phase.prompts.forEach((prompt, promptIdx) => {
                if (prompt.prompt_id.toString() === action.payload.promptId.toString()) {
                  state.value[blockIdx].phases[
                    phaseIdx
                  ].prompts[promptIdx] = {
                    prompt_id: prompt.prompt_id,
                    prompt: action.payload.prompt,
                    position: phase.prompts.length,
                  };
                }
              });
            }
          });
        }
      });
    },
    deletePrompt: (state, action: PayloadAction<IDeletePrompt>) => {
      state.value.forEach((block, blockIdx) => {
        if (block.block_id.toString() === action.payload.blockId.toString()) {
          block.phases.forEach((phase, phaseIdx) => {
            if (phase.phase_id.toString() === action.payload.phaseId.toString()) {
              const filteredPrompts = phase.prompts.filter((prompt) => {
                return prompt.prompt_id.toString() !== action.payload.promptId.toString();
              });

              state.value[blockIdx].phases[phaseIdx].prompts = filteredPrompts;
            }
          });
        }
      });
    },
    deletePhase: (state, action) => {
        const { blockId, phaseId } = action.payload;
        const blockIndex = state.value.findIndex(b => b.block_id.toString() === blockId.toString());
        if (blockIndex === -1) return;
    
        const newPhases = state.value[blockIndex].phases
            .filter(phase => phase.phase_id !== phaseId)
            .map((phase, index) => ({ ...phase, position: index }));
    
        state.value[blockIndex].phases = newPhases;
    },
    updatePhase: (state, action: PayloadAction<IUpdatePhase>) => {
      state.value.forEach((block, blockIdx) => {
        if (block.block_id.toString() === action.payload.blockId.toString()) {
          block.phases.forEach((phase, phaseIdx) => {
            if (phase.phase_id.toString() === action.payload.phase_id.toString()) {
              const { blockId, ...updatedPhase } = action.payload;
              state.value[blockIdx].phases[phaseIdx] = updatedPhase;
            }
          });
        }
      });
    },
    saveBlockLocally: (state) => {
      const progress = JSON.stringify(state.value);
      localStorage.setItem('tiller-block-edit', progress);
    },
    reorderPhases: (state, action: PayloadAction<IReorderPhases>) => {
      const { blockId, startIndex, endIndex } = action.payload;
      const block = state.value.find(b => b.block_id.toString() === blockId.toString());
      if (!block) return;
      
      // Move phase in array
      const newPhases = Array.from(block.phases);
      const [removedPhase] = newPhases.splice(startIndex, 1);
      newPhases.splice(endIndex, 0, removedPhase);
      
      // Update position indices to match array indices
      newPhases.forEach((phase, index) => {
        phase.position = index;
      });
    
      block.phases = newPhases;
    },
    reorderPrompts: (state, action: PayloadAction<IReorderPrompts>) => {
      const { blockId, phaseId, startIndex, endIndex } = action.payload;
      const block = state.value.find(b => b.block_id.toString() === blockId.toString());
      if (!block) return;
      
      const phase = block.phases.find(p => p.phase_id.toString() === phaseId.toString());
      if (!phase) return;
      
      // Move prompt in array
      const newPrompts = Array.from(phase.prompts);
      const [removedPrompt] = newPrompts.splice(startIndex, 1);
      newPrompts.splice(endIndex, 0, removedPrompt);
      
      // Update position indices to match array indices
      newPrompts.forEach((prompt, index) => {
        prompt.position = index;
      });
    
      phase.prompts = newPrompts;
    },
    
  },
});

// Action creators are generated for each case reducer function
export const {
  addBlock,
  setBlocks,
  patchBlock,
  deleteBlock,
  addPhase,
  updatePhase,
  deletePhase,
  addPrompt,
  updatePrompt,
  deletePrompt,
  saveBlockLocally,
  reorderPhases,
  reorderPrompts
} = BlocksBuilder.actions;

export default BlocksBuilder.reducer;
