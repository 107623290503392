import { useState } from "react";
import { ConvModals } from "../types";

interface IProps {
    onSetConvTitle: (convTitle: string) => void;
    onNextModal: (modal: ConvModals) => void;
}
function SetupYourFirstConversation({ onSetConvTitle, onNextModal }: IProps) {
    const [convTitle, setConvTitle] = useState("");
    // const [productName, setProductName] = useState("");

    function handleConvoNameChange(e: any) {
        setConvTitle(e.target.value);
    }

    // function handleProductChange(e: any) {
    //     setProductName(e.target.value);
    // }

    function handleSubmit(e: any) {
        e.preventDefault();
        onSetConvTitle(convTitle);
        onNextModal(ConvModals.FirstObj);
    }

    return (
        <form method="POST" onSubmit={handleSubmit}>
            <img
                src="logo-black.svg"
                alt="company logo"
                width="103px"
                className="mx-auto pb-16"
            />
            <h2 className="header-2 text-center">
                Create your first playbook
            </h2>
            <p className="pb-1 text-[--grey-muted] text-center">
                Build your first playbook on Tiller and begin closing more
                leads, faster.
            </p>
            <div className="pt-4 pb-2">
                <label htmlFor="convo-id" className="block w-full">
                    What's this playbook called?
                </label>
                <input
                    type="text"
                    value={convTitle}
                    onChange={handleConvoNameChange}
                    id="convo-id"
                    className="form-input"
                    placeholder="Sell Beta"
                    required
                />
                <p className="text-[--grey-muted]">
                    Use a descriptive name so your teammates know what they're
                    looking at.
                </p>
            </div>

            {/* <div className="pt-4 pb-2">
                <label htmlFor="product-id" className="block w-full">
                    Associated product
                </label>
                <input
                    type="text"
                    value={productName}
                    onChange={handleProductChange}
                    id="product-id"
                    className="form-input"
                    placeholder="Tiller Beta"
                />
                <p className="text-[--grey-muted]">What are you selling?</p>
            </div> */}

            <button type="submit" className="btn btn-aqua w-full my-4">
                Next
            </button>
        </form>
    );
}

export default SetupYourFirstConversation;
