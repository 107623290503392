import React, { useEffect } from 'react';
import axios from 'axios';
import { useAppSelector, useAppDispatch } from '../hooks'
import { setFailed, setError, setSuccess } from '../state/xhr-status/reqStatus';
import AppNotifications from '../components/AppNotifications';
import { useNavigate } from 'react-router-dom';
import Loader from "../components/Loaders/Loader";

const HandleOAuthRedirect = () => {
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    const session = sessionStorage.getItem("tiller") || localStorage.getItem("tiller");
    const access_token = session ? JSON.parse(session).access_token : null;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        if (code) {
            exchangeCodeForTokens(code);
        }else {
            dispatch(setFailed({msg: "Failed to connect Microsoft Calender", statusCode:401}))
            navigate("/settings");
        }
    }, [navigate]);

    const exchangeCodeForTokens = async (code:string) => {
        try {
            const response = await fetch(`${apiUrl}/microsoft/oauth2callback/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${access_token}`
                },
                body: JSON.stringify({ code })
            });
            const data = await response.json();

            if (response.ok) {
                dispatch(setSuccess({msg: data.message, statusCode: response.status}))
            } else {
                dispatch(setFailed({ msg: data.message, statusCode: response.status }));
            }
            navigate("/settings");
        } catch (error) {
            dispatch(setError({ msg: `Error exchanging code for tokens:${error}`, statusCode: 500 }));
            navigate("/settings");
        }
    };

    return <div className='h-screen'>
        <p className='z-10'>Redirecting to Tiller...</p>
        <Loader/>
    </div>;
};

export default HandleOAuthRedirect;
