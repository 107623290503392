import { useAuth } from '../contexts/AuthContext';
import { useState } from 'react';

import { useAppSelector, useAppDispatch } from '../hooks'

import AppLayout from "../layouts/AppLayout";
import AppNotifications from '../components/AppNotifications';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { setSuccess, setFailed, setError } from '../state/xhr-status/reqStatus';
import { FaSpinner, FaEye, FaEyeSlash } from "react-icons/fa";
import axios from 'axios';
import logoIcon from './../assets/images/logo-black.svg';
import { jwtDecode } from 'jwt-decode';

function SignIn() {
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');
    const [pwdVisible, setPwdVisible] = useState(false);
    const [remember, setRemember] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<{ email?: string; pwd?: string }>({});
    const { setUser } = useAuth();

    const reqStatus = useAppSelector((state) => state.reqStatus.value);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();

    const from = location.state?.from?.pathname;

    function handleEmailChange(e: any) {
        setEmail(e.target.value);

    }

    function handlePwdChange(e: any) {
        setPwd(e.target.value);
    }

    function handleRememberMeChange(e: any) {
        setRemember(e.target.checked);
    }

    function validateForm() {
        let valid = true;
        let errors = { email: '', pwd: '' };

        if (!email) {
            errors.email = 'Email is required';
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email address is invalid';
            valid = false;
        }

        if (!pwd) {
            errors.pwd = 'Password is required';
            valid = false;
        } else if (pwd.length < 8) {
            errors.pwd = 'Password must be at least 8 characters long';
            valid = false;
        }

        setErrors(errors);
        return valid;
    }


    async function handleSubmit(e: any) {
        e.preventDefault();
        if (!validateForm()) return;
        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', pwd);
        if (remember) formData.append('remember', 'True');



        try {
            setLoading(true);
            const response = await axios.post(`${apiUrl}/api/signin/`, formData);
            setLoading(false);
            if (response.status == 200) {
                const accessToken = response.data.access_token

                const decoded = jwtDecode<{ sub: string, user_name: string, roles: string[], company_id: string }>(accessToken);

                if (decoded.sub && decoded.user_name && decoded.roles && decoded.roles.length > 0 && decoded.company_id) {
                    setUser({ id: decoded.sub, username: decoded.user_name, roles: decoded.roles, company_id: decoded.company_id });
                    localStorage.setItem("tiller", JSON.stringify(response.data));
                    // Check if the redirect location is '/signin', navigate to '/' if true, otherwise to 'from'
                    const destination = from === '/signin' ? '/' : from || '/';
                    navigate(destination, { replace: true });
                } else {
                    console.log('Token is missing required fields');
                    dispatch(setFailed({ msg: 'Access Token is invalid', statusCode: 401 }));
                    localStorage.removeItem("tiller");
                    navigate('/signin'); // Return null if any required field is missing or roles is empty
                }
            } else {
                dispatch(setFailed({ msg: response.data.message, statusCode: response.status }));
            }

        } catch (error: any) {
            setLoading(false);
            if (axios.isAxiosError(error) && error.response) {
                dispatch(setError({ msg: error.response.data.message, statusCode: error.response.status }));
            } else {
                dispatch(setError({ msg: "Network error or server is unreachable.", statusCode: 500 }));
            }
        }
    }

    return (
        <AppLayout>

            <form method="POST" onSubmit={handleSubmit}>
                <img src={logoIcon} alt="company logo" width="103px" />
                <h2 className="text-xl pt-2 font-medium mt-20">Sign in to Your account</h2>
                <p className="auth-p">Build your first call script on Tiller and begin closing more leads, faster.</p>
                {reqStatus && (
                    <AppNotifications {...reqStatus} />
                )}

                <div className="py-2">
                    <label htmlFor="signin-email" className="block w-full">Email</label>
                    <input type="email" value={email} onChange={handleEmailChange} id="signin-email" className="form-input" />
                    {errors.email && <p className="text-red-500 text-xs pb-">{errors.email}</p>}
                </div>

                <div className='py-2'>
                    <div className="relative">
                        <label htmlFor="signin-pwd" className="block w-full">Password</label>
                        <input type={pwdVisible ? "text" : "password"} value={pwd} onChange={handlePwdChange} id="signin-pwd" className="form-input pr-10" />
                        <button
                            type="button"
                            onClick={() => setPwdVisible(!pwdVisible)}
                            className="absolute inset-y-0 right-0 pr-3 mt-6 flex items-center text-sm leading-5"
                        >
                            {pwdVisible ? <FaEyeSlash /> : <FaEye />}
                        </button>

                    </div>
                    {errors.pwd && <p className="text-red-500 text-xs pb-">{errors.pwd}</p>}
                </div>


                <label className="inline-flex items-center mb-5 cursor-pointer">
                    <input type="checkbox" checked={remember} className="sr-only peer" onChange={handleRememberMeChange} />
                    <div
                        className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-[--aqua]"></div>
                    <span className="ms-3 text-sm">Remember me</span>
                </label>

                <button
                    type="submit"
                    className={`btn btn-aqua w-full my-4 ${loading ? 'cursor-progress bg-[--aqua-dark]' : ''}`}
                    disabled={loading ? true : false}>
                    Sign in
                    {loading && (
                        <FaSpinner className="ml-2 inline animate-spin" />
                    )}
                </button>

                <div className="flex mb-2">
                    <p className="text-gray-500 text-sm">Don't have an account?</p>
                    <Link className="pl-2 text-[--aqua-dark] font-bold text-sm" to="/signup">
                        Sign Up
                    </Link>
                </div>
                <div className="flex">
                    <Link className="text-[--grey-muted] underline" to="/forgot-password">
                        I've forgotten my password
                    </Link>
                </div>
            </form>
        </AppLayout>
    );
}

export default SignIn;