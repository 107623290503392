import {Link} from 'react-router-dom';

function NotFound() {
    return (
        <main className="flex items-center justify-center h-screen">
            <div className="px-8 max-w-lg">
                <h2 className='header-2'>404 - PAGE NOT FOUND</h2>
                <p className='py-4'>The page you are looking for might not exist, has been removed, had its name changed or its temporarily unavailable.</p>
                <Link className="btn btn-aqua" to="/">HOME PAGE</Link>
            </div>
        </main>
    )
}

export default NotFound;
