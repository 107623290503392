import React, { useState, useEffect } from "react";
import { IBlock } from "../types";
import PreviewPrompterHeader from "../components/PreviewPrompterHeader";
import PrompterSideNav from "../components/PrompterSideNav";
import PrompterPrompt from "../components/PrompterPrompt";
import { FaRegFrownOpen } from "react-icons/fa";

const PreviewPrompter: React.FC = () => {
    const [blocks] = useState<IBlock[]>(JSON.parse(localStorage.getItem("tiller-popup-blocks") || "[]"));
    const [activeLink, setActiveLink] = useState(`section-${blocks!![0].block_id}`);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + 250;
            const sections = blocks.find(block => {
                const element = document.getElementById(`section-${block.block_id}`);
                if (element) {
                    return scrollPosition >= element.offsetTop && scrollPosition < element.offsetTop + element.offsetHeight;
                }
                return false;
            });
            if (sections) {
                setActiveLink(`section-${sections.block_id}`);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [blocks]);

    const handleSetActive = (to: string) => {
        setActiveLink(to);
    };

    const handleClick = (to: string) => {
        const element = document.getElementById(to);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
        setActiveLink(to);
    };

    return (
        <main className="relative h-screen flex justify-between">
            <PreviewPrompterHeader />
            <PrompterSideNav activeLink={activeLink} handleSetActive={handleSetActive} handleClick={handleClick} />
            <div >
                <ul className="ml-32 flex flex-1 flex-col pt-20 mt-4">
                    {blocks.length > 0 ? (
                        <>
                            {blocks.map((block) => (
                                <li className="px-5 mb-16" key={block.block_id}>
                                    <h2 className="font-bold text-lg">{block.title}</h2>
                                    <section id={`section-${block.block_id}`} className="py-4">
                                        {block.phases.map((phase, phaseIdx) => (
                                            <div key={phaseIdx} className="bg-gray-100 p-4 my-4 rounded-lg">
                                                <header className="font-medium text-lg flex items-center justify-between">
                                                    <h3>{phase.title}</h3>
                                                </header>
                                                <ul className="my-6">
                                                    {phase.prompts.map((prompt, promptIdx) => (
                                                        <li key={promptIdx} className="flex items-center justify-between gap-4 text-gray-500 my-4">
                                                            <PrompterPrompt id={prompt.prompt_id} prompt={prompt.prompt} />
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </section>
                                </li>
                            ))}
                            <li className="h-[60vh]" />  {/* This div ensures enough space at the bottom for scrolling */}
                        </>
                    ) : (
                        <li className="flex flex-col justify-center items-center mt-24 text-[#838383] -ml-32">
                            <div className="mb-2">
                                <FaRegFrownOpen style={{ fontSize: '38px' }} />
                            </div>
                            <div>This conversation is empty.</div>
                        </li>
                    )}
                </ul>
            </div>
        </main>
    );
};

export default PreviewPrompter;
