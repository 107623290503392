import { useEffect } from "react";
import { useAppDispatch } from "../hooks";
import { resetStatus } from "../state/xhr-status/reqStatus";

function useNotifStateReset() {
    const dispatch = useAppDispatch();
    useEffect(() => {
       dispatch(resetStatus())
    }, []);
}

export default useNotifStateReset;