import { lazy, Suspense } from 'react';
import { Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import './App.css';
import NotFound from './pages/NotFound';
import DashboardConversations from './pages/Dashboard/Conversations';
import DashboardSettings from './pages/Dashboard/Settings';
import DashboardAgents from './pages/Dashboard/Agents';
import SignIn from './pages/SignIn';
import Prompter from './pages/Prompter';
import PreviewPrompter from './pages/PreviewPrompter';
import AutoPrompter from './pages/AutoPrompter';
import Loader from './components/Loaders/Loader';
import HandleOAuthRedirect from './pages/OAuthRedirect';
import HandleMicrosoftOAuthRedirect from './pages/OAuthRedirectMicrosoft';
import PasswordReset from './pages/PasswordReset';
import { EditBlockProvider } from './contexts/EditBlockContext';
import { NavigationProvider } from './contexts/NavigationContext';
import AutoMeetingRedirector from './pages/AutoMeetingRedirector';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from './contexts/AuthContext';
import { setError } from "./state/xhr-status/reqStatus";
import { useAppDispatch } from "./hooks";
import useTokenRefresh from './hooks/useTokenRefresh';

const CompanySignUp = lazy(() => import('./pages/CompanySignUp'));
const SignUp = lazy(() => import('./pages/SignUp'));
const UserSignUp = lazy(() => import('./pages/UserSignUp'));
const UserSignUp2 = lazy(() => import('./pages/UserSignUp2'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ConversationBuilder = lazy(() => import('./pages/ConversationBuilder'));
const Recording = lazy(() => import('./pages/Recording'))
const RecordingsList = lazy(() => import('./pages/Dashboard/RecordingsList'))
const Feed = lazy(() => import('./pages/Dashboard/Feed'))

interface DecodedToken {
  exp: number;
  sub: string;
  user_name: string;
  roles: string[];
  company_id: string;
}

function App() {
  useTokenRefresh();
  
  const ProtectedRoute = ({ onlyManagers = false }: { onlyManagers?: boolean }) => {
    const location = useLocation();
    const { user, isManager } = useAuth();
    const session = localStorage.getItem("tiller");
    const accessToken = session ? JSON.parse(session).access_token : null;
    const dispatch = useAppDispatch();

    if (!accessToken || !user) {
      localStorage.removeItem('tiller');
      return <Navigate to="/signin" state={{ from: location }} replace />;
    }

    let decodedToken: DecodedToken;
    try {
      decodedToken = jwtDecode<DecodedToken>(accessToken);

      if (decodedToken.exp < Date.now() / 1000) {
        console.error("Session has expired");
        dispatch(setError({ msg: "Your Session has expired. Please login.", statusCode: 401 }));
        localStorage.removeItem('tiller');
        return <Navigate to="/signin" replace />;
      }

      if (onlyManagers && !isManager) {
        return <Navigate to="/error" replace />;
      }
    } catch (error) {
      console.error("Failed to decode token:", error);
      localStorage.removeItem('tiller');
      return <Navigate to="/signin" replace />;
    }

    return <Outlet />;
  };

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/company-signup" element={<CompanySignUp />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/user-signup" element={<UserSignUp />} />
        <Route path="/user-signup/:company" element={<UserSignUp2 />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<PasswordReset />} />
        <Route path="/oauth2callback" element={<HandleOAuthRedirect />} />
        <Route path="/microsoft/oauth2callback" element={<HandleMicrosoftOAuthRedirect />} />
        <Route path="/auto-meeting/:encodedMeetingLink" element={<AutoMeetingRedirector />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/playbooks" element={<DashboardConversations />} />
          <Route path="/" element={<Feed />} />
          <Route path="/settings" element={<DashboardSettings />} />
          <Route path="/agents" element={<DashboardAgents />} />
          <Route path="/conversation-builder/:encodedConvoId" element={<NavigationProvider><EditBlockProvider> <ConversationBuilder /> </EditBlockProvider> </NavigationProvider>} />
          <Route path="/prompter" element={<Prompter />} />
          <Route path="/preview-prompter" element={<PreviewPrompter />} />
          <Route path="/auto-prompter" element={<AutoPrompter />} />
          <Route path="/recording/:user_id/:call_id" element={<Recording />} />
          <Route path="/recordings" element={<RecordingsList />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
