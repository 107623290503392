import { EndCallModals } from "../types";
import PrompterDialogLayout from "../layouts/DialogLayout";

interface Props {
    goTo: (destination: string | null) => void;
    setIsEndCall: (isEnd: boolean) => void;
}


function EndCallDialog({ goTo, setIsEndCall }: Props) {
    function handleClickEndCall() {
        goTo(EndCallModals.RateConversation);
        setIsEndCall(true);
    }

    return (
        <PrompterDialogLayout>
            <h3 className="font-bold text-lg mb-2">End Call</h3>
            <button
                className="btn bg-red-500 text-white w-full my-2 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                onClick={() => handleClickEndCall()}
            >
                End call
            </button>
            <button
                className="btn btn-outline w-full my-2 hover:bg-gray-200"
                onClick={() => goTo(null)}
            >
                Cancel
            </button>
        </PrompterDialogLayout>
    );
}

export default EndCallDialog;
