import { useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const useTokenRefresh = () => {
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    const { user, setUser } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const session = localStorage.getItem("tiller");
        const accessToken = session ? JSON.parse(session).access_token : null;
        const refreshToken = session ? JSON.parse(session).refresh_token : null;

        if (!accessToken) {
            console.log("No access token found in session.");
            return;
        }

        const refreshAccessToken = async () => {
            try {
                console.log("Refreshing access token...");
                const response = await fetch(`${apiUrl}/api/refresh`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${refreshToken}`
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    const sessionData = JSON.parse(localStorage.getItem("tiller") || '{}');
                    sessionData.access_token = data.access_token;
                    localStorage.setItem("tiller", JSON.stringify(sessionData));
                    const newAccessToken = data.access_token;
                    const decoded = jwtDecode<{ sub: string, user_name: string, roles: string[], company_id: string }>(newAccessToken);

                    if (user?.id !== decoded.sub || user?.username !== decoded.user_name || JSON.stringify(user?.roles) !== JSON.stringify(decoded.roles) || user?.company_id !== decoded.company_id) {
                        setUser({ id: decoded.sub, username: decoded.user_name, roles: decoded.roles, company_id: decoded.company_id });
                    }

                    console.log("Token refreshed successfully.");
                } else {
                    console.error("Failed to refresh access token:", response.statusText);
                    navigate("/signin", { state: { from: location }, replace: true });
                }
            } catch (error) {
                console.error("Error refreshing token:", error);
                navigate("/signin", { state: { from: location }, replace: true });
            }
        };

        const checkTokenAndRefresh = () => {
            let decodedToken;
            try {
                decodedToken = jwtDecode<{ exp: number }>(accessToken);
                if (typeof decodedToken.exp !== 'number') {
                    throw new Error("Token expiration time (exp) is missing or invalid");
                }
            } catch (error) {
                console.error("Failed to decode token or 'exp' is missing:", error);
                localStorage.removeItem('tiller');
                navigate("/signin", { replace: true });
                return;
            }

            const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
            const thirtyMinutesBeforeExpiry = expirationTime - (30 * 60 * 1000);
            const currentTime = Date.now();
            const timeoutDuration = thirtyMinutesBeforeExpiry - currentTime;

            console.log('Current Time:', new Date(currentTime).toLocaleString());
            console.log('Token Expiration Time:', new Date(expirationTime).toLocaleString());
            console.log('Scheduled Refresh Time:', new Date(thirtyMinutesBeforeExpiry).toLocaleString());
            console.log('Timeout Duration (seconds):', timeoutDuration / 1000);

            if (currentTime >= thirtyMinutesBeforeExpiry) {
                console.log("Token is within the 30-minute window or expired. Refreshing now...");
                refreshAccessToken();
            } else {
                console.log("Token is still valid. No need to refresh yet.");
            }
        };

        checkTokenAndRefresh(); // Initial check
        const intervalId = setInterval(checkTokenAndRefresh, 15 * 60 * 1000); // Check every 15 minutes

        return () => {
            clearInterval(intervalId); // Clean up the interval on component unmount
            console.log("Cleared token refresh interval.");
        };
    }, [apiUrl, navigate, location, setUser, user]);

    return null;
};

export default useTokenRefresh;
