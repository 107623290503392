import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { FaSpinner, FaExclamationTriangle, FaStar, FaRegStar } from "react-icons/fa";

import ConversationIcon from "./../assets/images/ConversationIcon.svg";
import { useAppDispatch } from "../hooks";
import { setBlocks } from "../state/blocks/data";
import { BlockFetchStatus } from "../types";
import { resetStatus } from "../state/xhr-status/reqStatus";
import { useAuth } from "../contexts/AuthContext";


interface ConversationCardProps {
    title?: string; // Assume title is always a string
    version?: string; // Assume version is always a string
    id: string;
    isFavorite: boolean;
    setEmptyOrFailedNotif: (msg: string, status: BlockFetchStatus) => void;
    onOpenStartCallModal: (id?: string) => void;
    setIsPrompterValid: (mode: boolean) => void;
    toggleFavorite: (convId: string) => void;
}

function ConversationCard({
    title,
    version,
    id,
    isFavorite,
    setEmptyOrFailedNotif,
    onOpenStartCallModal,
    setIsPrompterValid,
    toggleFavorite
}: ConversationCardProps) {
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    const [previewLoading, setPreviewLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user, setUser, isManager } = useAuth();

    const [blockFetchStatus, setBlockFetchStatus] =
        useState<BlockFetchStatus | null>(null);

    const dispatch = useAppDispatch();



    async function fetchAndStoreBlocks() {

        try {
            setIsPrompterValid(false)
            const session = sessionStorage.getItem("tiller") || localStorage.getItem("tiller");
            const access_token = session
                ? JSON.parse(session).access_token
                : null;

            const response = await fetch(
                `${apiUrl}/conversation/${id}`,
                {
                    method: "GET", // or 'POST' depending on your needs
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${access_token}`, // Include the token in the Authorization header
                    },
                }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();

            dispatch(setBlocks(data.blocks));

            if (data.blocks.length > 0) {
                setBlockFetchStatus(BlockFetchStatus.OK);
                dispatch(resetStatus()); // reset notifs
                setIsPrompterValid(true); // allow to open prompter
            } else {
                setBlockFetchStatus(BlockFetchStatus.Empty);
                setEmptyOrFailedNotif(
                    `Blocks for "${title}" are empty`,
                    BlockFetchStatus.Empty
                );
                setTimeout(() => {
                    setBlockFetchStatus(null);
                }, 6000);
            }

            localStorage.setItem(
                "tiller-popup-blocks",
                JSON.stringify(data.blocks)
            );
            localStorage.setItem(
                "tiller-popup-conv",
                JSON.stringify(title)
            );
        } catch (error) {
            console.error("Failed to fetch conversation:", error);
            setBlockFetchStatus(BlockFetchStatus.Failed);
            setEmptyOrFailedNotif(
                `Loading blocks for "${title}" failed. Please try again.`,
                BlockFetchStatus.Failed
            );
        }
    }

    async function handleClickStartCall() {
        setLoading(true);
        await fetchAndStoreBlocks(); // store in local storage
        setLoading(false);
        // if (blockFetchStatus === BlockFetchStatus.OK) {
        //     dispatch(resetStatus()); // reset notifs
        //     setIsPrompterValid(true); // allow to open prompter
        // }
        onOpenStartCallModal(id);
    }

    const navigate = useNavigate();

    const handleClickEditConversation = () => {
        navigate(`/conversation-builder/${btoa(id)}`);
    };
    function openPreviewWindow() {
        const width = 680; // width of the new window
        const height = window.screen.height; // use the full screen height
        const left = window.screen.width; // position the window on the right side
        const top = 0; // start at the top of the screen
        const previewWindow = window.open(
            `${window.location.origin}/preview-prompter`, // URL to open
            "_blank", // open in a new window
            `toolbar=no, location=no, directories=no, status=no, menubar=no, 
        scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
        height=${height}, top=${top}, left=${left}`
        );
        // Check if the new window was blocked by popup blocker
        if (previewWindow) {
            previewWindow.onload = function () {
                previewWindow.document.title = "Preview Prompter"; // Set the title of the new window
            };
        } else {
            alert('Popup was blocked! Please allow popups for this website.');
        }
    }

    async function handleClickPreview() {
        setPreviewLoading(true);
        await fetchAndStoreBlocks();
        setPreviewLoading(false);
        openPreviewWindow();
    };


    return (
        <div className="bg-white shadow-[0_2px_18px_-6px_rgba(0,0,0,0.2)] mb-4 px-6 py-3 w-[100%]  rounded-xl w-full">
            <div className="flex items-center justify-between ">
                <div className="flex flex-row items-center ">
                    <img src={ConversationIcon} alt="conversation icon" />
                    <div className="ml-2">
                        <p className="text-sm xl:text-base font-medium ">{title}</p>
                        <p className="text-xs text-custom-gray xl:text-sm">
                            version {version}
                        </p>
                    </div>
                </div>

                <div className="flex  flex-row   items-center justify-center lg:items-end space-x-2 lg:space-y-0 lg:space-x-2 absolute lg:relative top-full right-0 bg-white lg:bg-transparent shadow-md lg:shadow-none p-2 lg:p-0">
                    <button
                        onClick={() => toggleFavorite(id)}
                        className={`px-3 py-1 ${isFavorite ? 'text-yellow-500' : 'text-[#383838]'
                            }`}
                        disabled={loading}
                        title={isFavorite ? "Remove from Favorites" : "Add to Favorites"}
                    >
                        {isFavorite ? <FaStar size={24} /> : <FaRegStar size={24} />}
                    </button>

                    <button
                        className="font-medium  rounded border hover:shadow-lg w-[70px] flex items-center justify-center py-1"
                        onClick={handleClickEditConversation}
                    >
                        Edit
                    </button>

                    <button className={`font-medium  rounded border hover:shadow-lg flex items-center justify-center py-1 ${previewLoading ? "cursor-not-allowed w-[100px]" : "cursor-pointer w-[90px]"}`}
                        onClick={handleClickPreview}>
                        Preview&nbsp;&nbsp;
                        {previewLoading && (
                            <FaSpinner className="inline animate-spin" />
                        )}
                    </button>

                    <button
                        disabled={loading}
                        onClick={() => handleClickStartCall()}
                        className={`font-medium btn-aqua  rounded flex items-center justify-center py-1 ${loading ? "cursor-not-allowed w-[120px]" : "cursor-pointer w-[110px]"
                            }`}
                    >
                        Start call&nbsp;&nbsp;
                        {loading && (
                            <FaSpinner className="inline animate-spin" />
                        )}
                        {!loading &&
                            blockFetchStatus !== BlockFetchStatus.OK &&
                            blockFetchStatus !== null && (
                                <div
                                    title={`loading blocks ${blockFetchStatus}`}
                                >
                                    <FaExclamationTriangle color="yellow" />
                                </div>
                            )}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ConversationCard;
