import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaRegFrownOpen } from "react-icons/fa";
import ConversationIcon from "./../assets/images/ConversationIcon.svg";
import Loader from "./Loaders/Loader";

export interface IEditBlockProps {
    activeAgentId: string | null;
    activeAgentName: string | null;
    activeAgentEmail: string | null;
    setIsOpenAgentBlock: (x: boolean) => void;
}

interface Call {
    id: string,
    title: string;
    start_time: string;
    end_time: string;
}

function AgentHistoryBlock({ activeAgentId, activeAgentName, activeAgentEmail, setIsOpenAgentBlock }: IEditBlockProps) {
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    const [isLoading, setIsLoading] = useState(true);
    const session = sessionStorage.getItem("tiller") || localStorage.getItem("tiller");
    const access_token = session ? JSON.parse(session).access_token : null;
    const navigate = useNavigate()
    const [calls, setCalls] = useState<Call[]>([]);

    useEffect(() => {
        fetchAgentHistory();
    }, [activeAgentId]);

    async function fetchAgentHistory() {
        setIsLoading(true);
        try {
            const response = await fetch(`${apiUrl}/call-history/${activeAgentId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }
            });
            if (!response.ok) {
                throw new Error('Agent has no call history');
            }
            const data = await response.json();
            setCalls(data);
        } catch (error) {
            console.error("Failed to fetch call history:", error);
        } finally {
            setIsLoading(false); // Disable loading state
        }
    }

    function getTimeDiff(startDate: string, endDate: string) {
        const startTime = new Date(startDate);
        const endTime = new Date(endDate);
        // Ensure the end date is after the start date
        if (endDate < startDate) {
            return null;
        }

        const diffInMilliseconds: number = endTime.getTime() - startTime.getTime();

        // Calculate total minutes and total seconds
        const totalSeconds: number = Math.floor(diffInMilliseconds / 1000);
        const minutes: number = Math.floor(totalSeconds / 60);
        const seconds: number = totalSeconds % 60;
        if (minutes < 0 || seconds < 0) {
            return ""

        } else {
            return ` • ${minutes}m ${seconds}s`;
        }
    }

    function openRecording(user_id: string, call_id: string) {
        navigate(`/recording/${user_id}/${call_id}`);
    }

    return (
        <div className="absolute fixed top-0 right-0 flex flex-auto w-full h-screen">
            {/* overlay */}
            <div
                className="hidden md:block md:bg-gray-800 md:opacity-50 md:w-1/4 lg:w-full"
                title="close edit block"
                onClick={() => setIsOpenAgentBlock(false)}
            >
                &nbsp;
            </div>
            {/* overlay */}
            <div className="bg-white w-full px-4 lg:max-w-2xl overflow-auto h-screen">
                <header className="fixed bg-white pt-4 w-full">
                    <div className="text-2xl font-bold">{activeAgentName}</div>
                    <p className="text-sm text-[#6B7280]">{activeAgentEmail}</p>
                    <div className="my-4 font-medium">History</div>
                </header>

                <main className="mt-32">

                    {isLoading ? (
                        <Loader />
                    ) : calls.length > 0 ? (
                        calls.map((call) => (
                            <div key={call.id} className="border border-[#0000001A] bg-[#FCFCFC] mb-4 px-6 py-3 w-full rounded-xl cursor-pointer">
                                <div className="flex items-center justify-between ">
                                    <div className="flex flex-row items-center ">
                                        <img src={ConversationIcon} alt="block icon" />
                                        <div className="ml-2">
                                            <p className="text-base font-medium">{call.title}</p>
                                            <p className="text-xs text-custom-gray lg:text-sm">
                                                {new Date(call.start_time).toLocaleString()} {getTimeDiff(call.start_time, call.end_time)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="flex flex-col justify-center items-center mt-24 text-[#838383] ">
                            <div className="mb-2">
                                <FaRegFrownOpen style={{ fontSize: '38px' }} />
                            </div>
                            <div>No call history available.</div>
                        </div>
                    )}
                </main>


            </div>
        </div>
    );
}

export default AgentHistoryBlock;
