type LayoutProps = {
    children: React.ReactNode;
};

const DailogLayout: React.FC<LayoutProps> = ({ children }) => (
  <div className="bg-gray-800 bg-opacity-50 z-50 fixed inset-0 flex items-center justify-center">
    <div className="p-8 mx-auto max-w-lg bg-[--grey-98] shadow rounded-3xl">
      {children}
    </div>
  </div>
);

export default DailogLayout;