import React, { useState } from 'react';
import myImage from './../assets/images/onboarding-auth.webp';
import DashboardSideNav from '../components/DashboardSideNav';
import FeedbackModal from '../components/FeedbackModal';

type LayoutProps = {
    children: React.ReactNode;
};

const DashboardLayout: React.FC<LayoutProps> = ({ children }) => {
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

    const handleOpenFeedbackModal = () => setIsFeedbackModalOpen(true);
    const handleCloseFeedbackModal = () => setIsFeedbackModalOpen(false);

    return (
        <main className="flex h-screen overflow-hidden">
            <DashboardSideNav onOpenFeedbackModal={handleOpenFeedbackModal} />
            <div className="relative flex-1 flex flex-col pl-4  pt-6 ">
                {children}
            </div>
            {isFeedbackModalOpen && (
                <FeedbackModal onClose={handleCloseFeedbackModal} />
            )}
        </main>
    );
};

export default DashboardLayout;
