import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../hooks'
import { resetStatus } from '../state/xhr-status/reqStatus';

interface IReqStatus {
    status: string,
    msg?: string,
    statusCode: number
}

const AppNotifications: React.FC<IReqStatus> = ({ status, msg, statusCode }) => {
    const dispatch = useAppDispatch();
    const [isVisible, setIsVisible] = useState(true);

    function closeNotification() {
        setIsVisible(false);
        dispatch(resetStatus());
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            closeNotification();
        }, 7000);
        return () => clearTimeout(timer);
    }, [status, msg]);

    if (!isVisible) return null;

    let bgColor = "bg-blue-100 border-[--aqua] text-[--aqua]"; // Default to success
    let iconColor = "text-[--aqua]";

    if (status === "failed" || status === "error") {
        bgColor = "bg-red-100 border-red-400 text-red-700";
        iconColor = "text-red-500";
    }

    return (
        <div className={`${bgColor} border px-4 py-3 z-50 mx-auto rounded-lg shadow-md fixed top-0 left-1/2 transform -translate-x-1/2 mt-8`} style={{ maxWidth: '500px', width: '100%' }} role="alert">
            <span className="block sm:inline">{msg}</span>
            <button className="absolute top-0 right-0 m-2" onClick={closeNotification}>
                <svg className={`fill-current h-6 w-6 text-${iconColor}-600 hover:text-${iconColor}-800`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <title>Close</title>
                    <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
            </button>
        </div>
    );
};

export default AppNotifications;
