import { useEffect, useState } from "react";

import { EndCallModals, IBlock } from "../types";

import PrompterHeader from "../components/PrompterHeader";
import PrompterSideNav from "../components/PrompterSideNav";
import PrompterEndCallDialog from "../components/PrompterEndCallDialog";
import PrompterRateConversation from "../components/PrompterRateConversation";
import PrompterAnyComments from "../components/PrompterAnyComments";
import AppNotifications from "../components/AppNotifications";
import PrompterPrompt from "../components/PrompterPrompt";
import { FaChevronLeft, FaChevronRight, FaChevronCircleLeft,  FaChevronCircleRight} from "react-icons/fa";

import { useAppSelector } from "../hooks";

function Prompter() {
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    const session = sessionStorage.getItem("tiller") || localStorage.getItem("tiller");
    const access_token = session ? JSON.parse(session).access_token : null;
    const reqStatus = useAppSelector((state) => state.reqStatus.value);
    const [blocks] = useState<IBlock[]>(JSON.parse(localStorage.getItem("tiller-popup-blocks")!!));
    const [isSideNavOpen, setIsSideNavOpen] = useState(() => {
        const savedState = localStorage.getItem("isSideNavOpen");
        return savedState !== null ? JSON.parse(savedState) : true;
    });
    const [activeLink, setActiveLink] = useState(
        `section-${blocks!![0].block_id}`
    );
    const [isEndCall, setIsEndCall] = useState(false);
    const [activeModal, setActiveModal] = useState<null | string>(null);
    const [endTime, setEndTime] = useState<Date | null>(null);
    const [rating, setRating] = useState<number>(0);
    const [comment, setComment] = useState<string>('');
    const call_id = localStorage.getItem("tiller-call-id");

    function goTo(destination: string | null) {
        setActiveModal(destination);
    }

    function handleClickEndCall() {
        goTo(EndCallModals.EndCall);
    }

    async function submitCallDetails() {
        if (!endTime || rating === 0 || !comment) {
            console.error("Required fields are missing!");
            return;
        }

        const data = {
            call_id,
            rating,
            comment
        };

        try {
            const response = await fetch(`${apiUrl}/end-call/`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${access_token}`,

                },
                body: JSON.stringify(data)
            });

            const responseData = await response.json();
            if (response.ok) {
                console.log("Call details submitted successfully:", responseData);
            } else {
                console.error("Failed to submit call details:", responseData);
            }
        } catch (error) {
            console.error("Error submitting call details:", error);
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + 250;
            const sections = blocks.find(block => {
                const element = document.getElementById(`section-${block.block_id}`);
                if (element) {
                    return scrollPosition >= element.offsetTop && scrollPosition < element.offsetTop + element.offsetHeight;
                }
                return false;
            });
            if (sections) {
                setActiveLink(`section-${sections.block_id}`);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [blocks]);

    const handleSetActive = (to: string) => {
        setActiveLink(to);
    };

    const handleClick = (to: string) => {
        const element = document.getElementById(to);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
        setActiveLink(to);
    };

    useEffect(() => {
        localStorage.setItem("isSideNavOpen", JSON.stringify(isSideNavOpen));
    }, [isSideNavOpen]);

    return (
        <main className="relative h-screen flex justify-between">
            <PrompterHeader goTo={goTo} isEndCall={isEndCall} setEndTime={setEndTime} />
            <div
                className={`transition-transform duration-300 ${isSideNavOpen ? 'translate-x-0' : '-translate-x-full'} fixed top-0 left-0 h-full`}
            >
                {isSideNavOpen && <PrompterSideNav activeLink={activeLink} handleSetActive={handleSetActive} handleClick={handleClick} />}
            </div>
            <button
                className={`z-50 fixed top-1/2 h-12 w-8 transition-all duration-300 ${isSideNavOpen ? 'ml-28' : 'ml-1'}`}
                onClick={() => setIsSideNavOpen(!isSideNavOpen)}
            >
                {isSideNavOpen ? <FaChevronCircleLeft size={28} color="#2C81FF" /> : <FaChevronCircleRight size={28} color="#2C81FF" />}
            </button>
            <div className={`transition-all duration-300 ${isSideNavOpen ? 'ml-32' : 'ml-5'} flex-1`}>
                <ul className={`flex flex-col pt-20 mt-4`}>
                    <li key={'notif-key'} className="px-5 pb-2">
                        {reqStatus && (
                            <div className="mx-auto">
                                <AppNotifications {...reqStatus} />
                            </div>
                        )}
                    </li>
                    {blocks!!.map((block, blockIdx) => (
                        <li className="px-5 mb-16" key={block.block_id}>
                            <h2 className="font-bold text-lg">
                                {block.title}
                            </h2>
                            <section
                                id={`section-${block.block_id}`}
                                key={blockIdx}
                                className="py-4"
                            >
                                {block.phases.map((phase, phaseIdx) => (
                                    <div
                                        key={phaseIdx}
                                        className="bg-[--grey-100] p-4 my-4 rounded-lg"
                                    >
                                        <header className="font-medium text-lg flex items-center justify-between">
                                            <h3>
                                                {phase.title}
                                            </h3>
                                        </header>
                                        <ul className="my-6">
                                            {phase.prompts.map((prompt, promptIdx) => (
                                                <li
                                                    className="flex items-center justify-between gap-4 text-[#6B7280] my-4"
                                                    key={promptIdx}
                                                >
                                                    <PrompterPrompt
                                                        id={prompt.prompt_id}
                                                        prompt={prompt.prompt}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </section>
                        </li>
                    ))}
                    <li
                        className="flex flex-col gap-4 pb-16 px-5"
                        key={"end-call-key"}
                    >
                        <p className="font-bold">Call finished?</p>

                        <div className="my-2">
                            <button
                                className="btn btn-outline bg-[--grey-98] text-red-500 text-sm hover:bg-red-600 hover:text-white"
                                onClick={handleClickEndCall}
                            >
                                End call
                            </button>
                        </div>
                    </li>
                    <li className="h-[60vh]" />
                </ul>
            </div>
            {activeModal === EndCallModals.EndCall && (
                <PrompterEndCallDialog
                    goTo={goTo}
                    setIsEndCall={setIsEndCall}
                />
            )}
            {activeModal === EndCallModals.RateConversation && (
                <PrompterRateConversation goTo={goTo} setRating={setRating} />
            )}
            {activeModal === EndCallModals.AnyComments && (
                <PrompterAnyComments goTo={goTo} setComment={setComment} onSubmit={submitCallDetails} />
            )}
        </main>
    );
}

export default Prompter;
