import { useState, useRef, useEffect } from "react";
import { FaSpinner } from "react-icons/fa";

import PrompterDialogLayout from "../layouts/DialogLayout";
import AppNotifications from "./AppNotifications";

import useNotifStateReset from "../hooks/useNotifStateReset";

import { useAppSelector, useAppDispatch } from "../hooks";
import { setFailed, setError, setSuccess } from "../state/xhr-status/reqStatus";

interface Props {
    conversationId?: string;
    isPrompterValid: boolean;
    onClose: () => void;
    openPrompterWindow: () => void;
}

function StartCallModal({ conversationId, isPrompterValid, onClose, openPrompterWindow }: Props) {
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    // useNotifStateReset(); // reset notifications

    const reqStatus = useAppSelector((state) => state.reqStatus.value);
    const dispatch = useAppDispatch();

    const [loadingLinkSubmit, setLoadingLinkSubmit] = useState(false);
    const [loadingNoLinkSubmit, setLoadingNoLinkSubmit] = useState(false);

    const [meetingLink, setMeetingLink] = useState("");

    const handleClose = () => {
        if (onClose) onClose(); // Call the onClose callback if provided
    };

    function handleMeetingLinkChange(e: any) {
        setMeetingLink(e.target.value);
    }

    const linkData = {
        meeting_url: meetingLink,
        conversation_id: conversationId, // Ensure this is properly handled, it can be undefined
    };

    const nolinkData = {
        conversation_id: conversationId, // Ensure this is properly handled, it can be undefined
    };

    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target as Node)
            ) {
                onClose(); // Close the modal if clicked outside of it
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    const session = sessionStorage.getItem("tiller") || localStorage.getItem("tiller");
    const access_token = session ? JSON.parse(session).access_token : null;

    async function handleLinkSubmit(e: any) {
        e.preventDefault();

        try {
            setLoadingLinkSubmit(true);
            const response = await fetch(`${apiUrl}/start-call/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${access_token}`,
                },
                body: JSON.stringify(linkData),
            });

            const data = await response.json();
            console.log("🚀 ~ handleLinkSubmit ~ data:", data)
            setLoadingLinkSubmit(false);

            if (response.ok) {
                localStorage.removeItem('tiller-call-id');
                localStorage.setItem('tiller-call-id', data['call_id'])
                console.log("local storage call id:", localStorage.getItem('tiller-call-id'))
                openPrompterWindow();
                handleClose(); // Close modal o
            } else {
                dispatch(
                    setFailed({
                        msg: data.message,
                        statusCode: response.status,
                    })
                );
            }
        } catch (error) {
            setLoadingLinkSubmit(false);
            dispatch(
                setError({
                    msg: "Something went wrong. Please try again.",
                    statusCode: 500,
                })
            );
        }
    }

    async function handleNoLinkSubmit(e: any) {
        e.preventDefault();

        try {
            setLoadingNoLinkSubmit(true);
            const response = await fetch(`${apiUrl}/start-call/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${access_token}`,
                },
                body: JSON.stringify(nolinkData),
            });

            const data = await response.json();
            setLoadingNoLinkSubmit(false);

            if (response.ok) {
                localStorage.setItem('tiller-call-id', data['call_id']);
                openPrompterWindow();
                handleClose(); // Close modal
            } else {
                dispatch(
                    setFailed({
                        msg: data.message,
                        statusCode: response.status,
                    })
                );
            }
        } catch (error) {
            setLoadingNoLinkSubmit(false);
            dispatch(
                setError({
                    msg: "Something went wrong. Please try again.",
                    statusCode: 500,
                })
            );
        }
    }

    return (
        <PrompterDialogLayout>
            <div ref={modalRef}>
                <form>
                    <h3 className="font-bold text-lg mb-2">
                        Provide a Meeting URL
                    </h3>

                    {reqStatus && <AppNotifications {...reqStatus} />}

                    <p className="text-[--grey-muted] pb-2">
                        Make sure your meeting URL permissions are set so that
                        Tiller Assisstant can join your call.
                    </p>
                    <input
                        onChange={handleMeetingLinkChange}
                        id="meeting-link"
                        className="form-input"
                        placeholder="https://meet.google.com/example"
                        autoComplete="off"
                    />

                    {!isPrompterValid && (
                        <button
                            onClick={handleLinkSubmit}
                            className={`btn btn-aqua w-full mt-4 ${
                                loadingLinkSubmit
                                    ? "cursor-progress bg-[--aqua-dark]"
                                    : ""
                            }`}
                            disabled={loadingLinkSubmit}
                        >
                            Join call
                            {loadingLinkSubmit && (
                                <FaSpinner className="ml-2 inline animate-spin" />
                            )}
                        </button>
                    )}
                    {isPrompterValid && (
                        <>
                            <button
                                onClick={handleLinkSubmit}
                                className={`btn btn-aqua w-full mt-4 ${
                                    loadingLinkSubmit
                                        ? "cursor-progress bg-[--aqua-dark]"
                                        : ""
                                }`}
                                disabled={loadingLinkSubmit}
                            >
                                Join call and start conversation
                                {loadingLinkSubmit && (
                                    <FaSpinner className="ml-2 inline animate-spin" />
                                )}
                            </button>

                            <button
                                onClick={handleNoLinkSubmit}
                                className={`btn btn-outline w-full my-2 hover:bg-gray-200 ${
                                    loadingNoLinkSubmit
                                        ? "cursor-progress bg-gray-200"
                                        : ""
                                }`}
                                disabled={loadingNoLinkSubmit}
                            >
                                Just start conversation
                                {loadingNoLinkSubmit && (
                                    <FaSpinner className="ml-2 inline animate-spin" />
                                )}
                            </button>                        
                        </>
                    )}
                </form>
            </div>
        </PrompterDialogLayout>
    );
}

export default StartCallModal;
