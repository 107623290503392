import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define a type for the context state
type NavigationContextType = {
    isUnsaved: boolean;
    setIsUnsaved: (isUnsaved: boolean) => void;
    nextLocation: string | null;
    confirmNavigation: () => void;
    blockNavigation: (location: string) => void;
};

// Creating the context with a default value
const NavigationContext = createContext<NavigationContextType | null>(null);

// Hook for easy access to the context
export function useNavigationContext() {
    const context = useContext(NavigationContext);
    if (!context) {
        throw new Error('useNavigationContext must be used within a NavigationProvider');
    }
    return context;
}

// Define a type for the provider props
interface NavigationProviderProps {
    children: ReactNode;
}

// Provider component
export const NavigationProvider: React.FC<NavigationProviderProps> = ({ children }) => {
    const [isUnsaved, setIsUnsaved] = useState<boolean>(false);
    const [nextLocation, setNextLocation] = useState<string | null>(null);

    const confirmNavigation = () => {
        setNextLocation(null);
        setIsUnsaved(false); // Clear the unsaved changes flag after navigation is confirmed
    };

    const blockNavigation = (location: string) => {
        setNextLocation(location);
    };

    return (
        <NavigationContext.Provider value={{ isUnsaved, setIsUnsaved, nextLocation, confirmNavigation, blockNavigation }}>
            {children}
        </NavigationContext.Provider>
    );
};
