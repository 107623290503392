import { useAuth } from "../contexts/AuthContext";

interface AgentCardProps {
    name?: string;    
    joinDate?: string; 
    userId?: string;
    currentUserId?: string;
    onDelete: () => void;
    onHistoryClick: () => void;
}


function AgentCard({ name, joinDate, userId,currentUserId, onDelete, onHistoryClick }: AgentCardProps) {
    const { user, isManager } = useAuth();
 
    return (
        <div className="bg-white shadow-[0_2px_18px_-6px_rgba(0,0,0,0.2)] mb-4 px-6 py-3 w-[100%] rounded-xl">
        <div className="flex items-center justify-between">
            <div>
                <p className="text-base font-medium">{name} {currentUserId === userId ? "(me)" : ""}</p>
                <p className="text-xs text-custom-gray lg:text-sm">Joined {joinDate}</p>
            </div>
            <div className="flex lg:flex flex-row lg:flex-row w-full lg:w-auto items-center justify-center lg:items-end space-x-2 lg:space-y-0 lg:space-x-2 absolute lg:relative top-full right-0 bg-white lg:bg-transparent shadow-md lg:shadow-none p-2 lg:p-0">
                <button onClick={onHistoryClick}
                        className="custom-button btn-nav">
                    History
                </button>
                {isManager &&
                <button onClick={onDelete}
                        className={`custom-button btn-nav ${currentUserId === userId ? 'text-gray-400 cursor-not-allowed' : 'text-[#ED6459]'}`}
                        disabled={currentUserId === userId}>
                    Remove user
                </button>}
            </div>
        </div>
    </div>
    
    );
}

export default AgentCard