import { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { FaCircle } from "react-icons/fa";
import { EndCallModals } from "../types";



function PreviewPrompterHeader() {
    const title = JSON.parse(localStorage.getItem("tiller-popup-conv")!!)

  

    return (
        <header className="fixed  inset-x-0 bg-[#FFFFFF] z-50 py-4 px-8 ">
            <div className="flex items-center justify-between h-full">
                <div className="flex justify-between">
                    <Link to="/">
                        <img
                            src="logo-black.svg"
                            alt="company logo"
                            width="83px"
                            height="19px"
                        />
                    </Link>
                    <div className="flex items-center text-xs font-bold ml-3">
                        {title}
                    </div>
                </div>

                
            </div>
        </header>
    );
}

export default PreviewPrompterHeader;
