import myImage from './../assets/images/onboarding-auth.webp';

type LayoutProps = {
     children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => (
        <div className="flex justify-center lg:justify-between ">
            <main className="flex flex-col items-center justify-center h-screen w-full lg:w-1/2">
                <div className="w-5/6 md:w-1/2 lg:w-[425px]">
                    {children}
                </div>
            </main>
            <aside className="hidden lg:flex lg:justify-center lg:items-center lg:w-1/2 lg:relative lg:text-white m-6 rounded-24" style={{backgroundImage: `url(${myImage})`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '24px'}}>
                <div className="z-10 text-center px-16">
                    <blockquote className="header-2">
                        "Today is always the most productive day of the week"
                    </blockquote>
                    <p className="text-lg">Lets get you in and selling today.</p>
                </div>
                <span className="absolute w-full h-full bg-gradient-to-r bg-[--aqua-dark] opacity-80" style={{borderRadius: '24px'}}>&nbsp;</span>
            </aside>
        </div>

);

export default Layout;
