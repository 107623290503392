import AutoPrompterConversationCard from "../components/AutoPrompterConversationCard"
import { Link } from "react-router-dom"
import { useState, useEffect } from "react";
import { setError, setFailed, setSuccess } from "../state/xhr-status/reqStatus";
import { useAppSelector, useAppDispatch } from "../hooks";
import useNotifStateReset from "../hooks/useNotifStateReset";
import SkeletonLoader from "../components/Loaders/SkeletonLoader";
import { BlockFetchStatus } from "../types";
import { FaRegFrownOpen } from "react-icons/fa";
import { setConvs } from "../state/convs/data";
import Prompter from "./Prompter";
import { useAuth } from "../contexts/AuthContext";



function AutoPrompter() {
    const [meetingLink, setMeetingLink] = useState("");
    const { user, setUser, isManager } = useAuth();

    useEffect(() => {
        const handlePostMessage = (event:any) => {
            if (event.origin !== window.location.origin) return;
            if (event.data.meetingLink) {
                setMeetingLink(event.data.meetingLink);
            }
        };

        window.addEventListener('message', handlePostMessage);

        // Clean up the event listener when the component unmounts
        return () => window.removeEventListener('message', handlePostMessage);
    }, []);

    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    const session = sessionStorage.getItem("tiller") || localStorage.getItem("tiller");

    const access_token = session ? JSON.parse(session).access_token : null;
    const company_id = user?.company_id;
    const dispatch = useAppDispatch();
    const conversations = useAppSelector((state) => state.convData.value);

    const [isLoading, setIsLoading] = useState(true);
    const [isStartCall, setIsStartCall] = useState(false);
    

    // function setEmptyOrFailedNotif(msg: string, status: BlockFetchStatus) {
    //     if (status === BlockFetchStatus.Empty) {
    //         dispatch(
    //             setFailed({
    //                 msg: msg,
    //                 statusCode: 200,
    //             })
    //         );
    //     } else {
    //         dispatch(
    //             setError({
    //                 msg: msg,
    //                 statusCode: 500,
    //             })
    //         );
    //     }
    // }

    const handleStartCall = (id?: string) => {
        setIsStartCall(true);
    };


    useEffect(() => {
        async function fetchConversations() {


            try {
                const response = await fetch(
                    `${apiUrl}/conversations/?companyId=${company_id}`,
                    {
                        method: "GET", // or 'POST' depending on your needs
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${access_token}`, // Include the token in the Authorization header
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                dispatch(setConvs(data));

            } catch (error) {
                console.error("Failed to fetch conversation:", error);
                dispatch(
                    setError({
                        msg: `Failed to fetch conversation: ${error}`,
                        statusCode: 500,
                    })
                );
            }
            setIsLoading(false);
        }

        fetchConversations();
    }, [access_token, company_id, dispatch]); // Empty dependency array means this effect runs once on mount

    return (
        
            !isStartCall ? (
              <main className="relative flex justify-between">
                <header className="fixed inset-x-0 bg-[#FFFFFF] z-50 py-4 px-8">
                  <div className="flex items-center justify-between h-full">
                    <Link to="/">
                      <img
                        src="logo-black.svg"
                        alt="company logo"
                        width="83px"
                        height="19px"
                      />
                    </Link>
                    <div className="flex-grow text-center font-bold">
                      Select a conversation
                    </div>
                    <div style={{ width: '83px' }}></div> {/* Placeholder for balancing the space */}
                  </div>
                </header>
          
                <div className="w-full pt-24 px-4">
                  {isLoading ? (
                    <SkeletonLoader />
                  ) : conversations.length > 0 ? (
                    conversations.map((conversation, index) => (
                      <AutoPrompterConversationCard
                        key={index}
                        title={conversation.title}
                        version={conversation.conv_version}
                        id={conversation.id}
                        onOpenStartCallModal={handleStartCall}
                        meetingLink={meetingLink}
                      />
                    ))
                  ) : (
                    <div className="flex flex-col justify-center items-center mt-24 text-[#838383]">
                      <div className="mb-2">
                        <FaRegFrownOpen style={{ fontSize: '38px' }} />
                      </div>
                      <div className="text-center">
                        No conversation scripts have been added to your workspace.<br />
                        Get in touch with your manager.
                      </div>
                    </div>
                  )}
                </div>
              </main>
            ) : (
              <Prompter />
            )       

    )
}

export default AutoPrompter
