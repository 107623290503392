import { useState } from "react";
import { FaSpinner } from "react-icons/fa";

import PrompterDialogLayout from "../layouts/DialogLayout";
import AppNotifications from "./AppNotifications";

import useNotifStateReset from "../hooks/useNotifStateReset";

import { useAppSelector, useAppDispatch } from "../hooks";
import { setFailed, setError, setSuccess } from "../state/xhr-status/reqStatus";

interface Props {
    goTo: (destination: string | null) => void;
    setComment: (comment: string) => void;
    onSubmit: () => Promise<void>;
}

function AnyComments({ goTo, setComment, onSubmit }: Props) {
    useNotifStateReset(); // reset notifs

    const reqStatus = useAppSelector((state) => state.reqStatus.value);
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);

    const [feedback, setFeedback] = useState("");

    function handleFeedbackChange(e: any) {
        setFeedback(e.target.value);
        setComment(e.target.value);
    }

    async function handleSubmit() {
        setLoading(true)
        await onSubmit(); // Call the function passed as prop
        setLoading(false)
        window.close();


        goTo(null);

    }

    return (
        <PrompterDialogLayout>
            <form method="POST" onSubmit={handleSubmit}>
                <h3 className="font-bold text-lg mb-2">Any comments?</h3>

                {reqStatus && <AppNotifications {...reqStatus} />}

                <p className="text-[--grey-muted] pb-2">
                    Let your manager know how the call went, or where things
                    might need changing.
                </p>
                <textarea
                    rows={5}
                    onChange={handleFeedbackChange}
                    id="end-call-feedback"
                    className="form-input"
                    placeholder="Feedback on the call or the script."
                ></textarea>

                <button
                    type="submit"
                    className={`btn btn-aqua w-full mt-4 ${loading ? "cursor-progress bg-[--aqua-dark]" : ""
                        }`}
                    disabled={loading ? true : false}
                >
                    Continue
                    {loading && (
                        <FaSpinner className="ml-2 inline animate-spin" />
                    )}
                </button>
            </form>
        </PrompterDialogLayout>
    );
}

export default AnyComments;