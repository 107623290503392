import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate, Link } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../hooks'
import { setFailed, setError, setSuccess } from '../state/xhr-status/reqStatus';

import { FaSpinner, FaEye, FaEyeSlash } from "react-icons/fa";

import AppLayout from "../layouts/AppLayout";
import AppNotifications from '../components/AppNotifications';
import logoIcon from './../assets/images/logo-black.svg';

function PasswordReset() {
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    const [newPwd, setNewPwd] = useState('');
    const [errors, setErrors] = useState<FormErrors>({});
    const { token } = useParams<{ token: string }>();
    const [loading, setLoading] = useState(false);
    const [pwdVisible, setPwdVisible] = useState(false);
    const reqStatus = useAppSelector((state) => state.reqStatus.value);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    function passwordCheck(password: string) {
        const lengthError = password.length < 8;
        const digitError = !/\d/.test(password);
        const uppercaseError = !/[A-Z]/.test(password);
        const lowercaseError = !/[a-z]/.test(password);
        const symbolError = !/\W/.test(password);

        return {
            passwordOk: !(lengthError || digitError || uppercaseError || lowercaseError || symbolError),
        };
    }
    interface FormErrors {
        pwd?: string;
        pwdDetails?: string;
    }

    function validateForm(): boolean {
        let tempErrors: FormErrors = {};


        const passwordErrors = passwordCheck(newPwd);
        if (!passwordErrors.passwordOk) {
            tempErrors.pwd = 'Password must be at least 8 characters, include an uppercase letter, a lowercase letter, a number, and a symbol.';
            tempErrors.pwdDetails = '';
        }



        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    }

    async function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            setLoading(true);
            const response = await fetch(`${apiUrl}/reset-password`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token, new_password: newPwd })
            })
            const data = await response.json();

            setLoading(false);

            if (response.ok) {
                dispatch(setSuccess({msg: data.message, statusCode: response.status}))
                const timer = setTimeout(() => {
                    navigate('/signin')
                }, 7300);
            } else {
                dispatch(setFailed({ msg: data.message, statusCode: response.status }));
            }
        } catch (error) {
            setLoading(false);
            dispatch(setError({ msg: "Invalid or expired token", statusCode: 500 }));
        }
    };

    return (
        <AppLayout>
            
            <form onSubmit={handleSubmit}>
                <img src={logoIcon} alt="company logo" width="103px" />
                <h2 className="font-medium mt-20 mb-5 text-lg">Reset password</h2>
                {reqStatus && (
                <div className="mx-auto mt-28">
                    <AppNotifications {...reqStatus} />
                </div>
            )}
                <div className='py-2'>
                    <div className="relative">
                        <label htmlFor="newPassword" className="block w-full">New Password</label>
                        <input type={pwdVisible ? "text" : "password"} value={newPwd} onChange={(e) => setNewPwd(e.target.value)} id="newPassword" className="form-input pr-10" required />
                        <button
                            type="button"
                            onClick={() => setPwdVisible(!pwdVisible)}
                            className="absolute inset-y-0 right-0 pr-3 mt-6 flex items-center text-sm leading-5"
                        >
                            {pwdVisible ? <FaEyeSlash /> : <FaEye />}
                        </button>

                    </div>
                    {errors.pwd && <p className="text-red-500 text-xs">{errors.pwd}</p>}
                    {errors.pwdDetails && <p className="text-red-500 text-xs">{errors.pwdDetails}</p>}
                </div>

                <button 
                    type="submit" 
                    className={`btn btn-aqua w-full my-4 ${loading ? 'cursor-progress bg-[--aqua-dark]' : ''}`}
                    disabled={loading ? true : false}>
                        Reset Password
                        { loading && (
                            <FaSpinner className="ml-2 inline animate-spin" />
                        )}
                    </button>
            </form>
        </AppLayout>
    );
};

export default PasswordReset;
