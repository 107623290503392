import { useState } from "react";
import { FaSpinner } from "react-icons/fa";

import PrompterDialogLayout from "../layouts/DialogLayout";
import AppNotifications from "./AppNotifications";

import useNotifStateReset from "../hooks/useNotifStateReset";

import { useAppSelector, useAppDispatch } from "../hooks";
import { setFailed, setError, setSuccess } from "../state/xhr-status/reqStatus";

interface Props {
    onClose: () => void;
}

function FeedbackModal({ onClose }: Props) {
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    const session = sessionStorage.getItem("tiller") || localStorage.getItem("tiller");
    const access_token = session ? JSON.parse(session).access_token : null;

    useNotifStateReset(); // reset notifs

    const reqStatus = useAppSelector((state) => state.reqStatus.value);
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);

    const [feedback, setFeedback] = useState("");

    const handleClose = () => {
        if (onClose) onClose();  // Call the onClose callback if provided
    };

    function handleFeedbackChange(e: any) {
        setFeedback(e.target.value);
    }

    async function handleSubmit(e: any) {
        e.preventDefault();

        try {
            setLoading(true);
            const response = await fetch(`${apiUrl}/feedback/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${access_token}`, // Include the token in the Authorization header
                },
                body: JSON.stringify({ feedback })
            });

            const data = await response.json();
            setLoading(false);

            if (response.ok) {
                dispatch(setSuccess({ msg: "Thanks for the feedback.", statusCode: response.status }));
                handleClose();  // Close modal on successful feedback submission
            } else {
                dispatch(setFailed({ msg: data.message, statusCode: response.status }));
            }
        } catch (error) {
            setLoading(false);
            dispatch(setError({ msg: "Something went wrong. Please try again.", statusCode: 500 }));
        }
        handleClose()
    }

    return (
        <PrompterDialogLayout>
            <form method="POST" onSubmit={handleSubmit}>
                <h3 className="font-bold text-lg mb-2">Tiller Beta feedback</h3>

                {reqStatus && <AppNotifications {...reqStatus} />}

                <p className="text-[--grey-muted] pb-2">
                    Positive or negative, we’d love to hear about your experience with Tiller.
                </p>
                <textarea
                    rows={5}
                    onChange={handleFeedbackChange}
                    id="end-call-feedback"
                    className="form-input"
                    placeholder="Let us know what we’re doing right or wrong"
                ></textarea>

                <button type="submit" className={`btn btn-aqua w-full mt-4 ${loading ? "cursor-progress bg-[--aqua-dark]" : ""}`} disabled={loading}>
                    Send feedback
                    {loading && <FaSpinner className="ml-2 inline animate-spin" />}
                </button>

                <button
                    className="btn btn-outline w-full my-2 hover:bg-gray-200"
                    onClick={handleClose}
                >
                    Cancel
                </button>
            </form>
        </PrompterDialogLayout>
    );
}

export default FeedbackModal;