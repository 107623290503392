import React from 'react';

const ConversationsSkeletonLoader: React.FC = () => {
    return (
        <div>
            {Array.from({ length: 10 }).map((_, index) => (
                <div key={index} className="bg-white shadow-[0_2px_18px_-6px_rgba(0,0,0,0.2)] mb-4 px-6 py-3 w-full rounded-xl animate-pulse">
                    <div className="flex items-center justify-between">
                        <div className='flex flex-row gap-1 items-center'>
                            <div className="h-[51px] w-[51px] bg-gray-200 rounded-full"></div>
                            <div className="flex flex-col justify-center">
                                <div className="h-4 bg-gray-200 rounded w-[150px] mb-2"></div>
                                <div className="h-3 bg-gray-200 rounded w-[70px]"></div>
                            </div>
                        </div>


                        <div className="flex flex-row space-x-2">
                            <div className="w-[70px] h-[38px] bg-gray-200 rounded"></div>
                            <div className="w-[80px] h-[38px] bg-gray-200 rounded"></div>
                            <div className="w-[110px] h-[38px] bg-gray-200 rounded"></div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ConversationsSkeletonLoader;
