import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IConversation } from "../../types";

// Define the initial state using that type
const initialState: { value: IConversation[]| [] } = {
    value: [],
};

export const ConvData = createSlice({
    name: "conv-data",
    initialState,
    reducers: {
        setConvs: (state, action: PayloadAction<IConversation[]>) => {
            state.value = action.payload;
        },
        addNewConv: (state, action: PayloadAction<IConversation>) => {
            state.value = [...state.value, action.payload]
        }
    },
});

// Action creators are generated for each case reducer function
export const { setConvs, addNewConv } = ConvData.actions;

export default ConvData.reducer;
