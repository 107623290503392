import { Link } from "react-scroll";
import { IBlock } from "../types";
import { useState } from "react";

interface PrompterSideNavProps {
    activeLink: string;
    handleSetActive: (to: string) => void;
    handleClick: (to: string) => void;
}

const PrompterSideNav: React.FC<PrompterSideNavProps> = ({ activeLink, handleSetActive, handleClick }) => {
    const [activeBlock] = useState<IBlock[]>(JSON.parse(localStorage.getItem("tiller-popup-blocks") || "[]"));

    return (
        <div className="fixed left-0 inset-y-0 flex flex-col pt-2 pb-10 transform w-44 text-gray-700 h-screen mt-20">
            {activeBlock.map((block, index) => (
                <Link
                    key={index}
                    className={`p-3 hover:bg-[#2C81FF] hover:text-white text-sm rounded-r-full mr-10 cursor-pointer ${activeLink === `section-${block.block_id}` ? 'font-bold' : ''}`}
                    to={`section-${block.block_id}`}
                    smooth={true}
                    duration={500}
                    offset={-50}
                    onSetActive={handleSetActive}
                    onClick={() => handleClick(`section-${block.block_id}`)}
                >
                    {block.title}
                </Link>
            ))}
        </div>
    );
};

export default PrompterSideNav;
